/**
 * Processors
 *
 */
import { keyIn } from '../utils/immutableHelpers';
import { sharedGeoShapeSelectors } from '../utils/selectors';
import { parseAui } from '../utils/parse';

import { AUI_RE } from '../constants';
import {fromJS} from "immutable";

const processors = {
  regexRemover({value, props}) {
    if (!((props || {}).regex instanceof RegExp)) {
      /* eslint-disable no-console */
      console.warn('"props.regex" is missing or invalid');
      /* eslint-enable no-console */
      return value;
    }
    return (typeof value === 'string') ? value.replace(props.regex, '') : value;
  },

  /**
   * Parses provided string and returns a resource part of Aui if present,
   * otherwise - returns the input string
   * @param  {String} value Input string
   * @return {String} Resource name or the input value
   */
  removeArn({ value }) {
    const { resource } = parseAui(value);
    return resource ?? value;
  },

  pickTagValue({ value, tag, rowData }) {
    return rowData ? rowData.getIn(['tags', tag]) : value;
  },

  filterTags({ value, tags }) {
    return value && value.filterNot(keyIn(...tags));
  },

  composeAui({ value, arn }) {
    if (AUI_RE.test(value)) {
      return value;
    }
    return arn ? `${arn}/${value}` : value;
  },

  getProperty({ value, rowData, prop, defaultValue }) {
    const displayProps = prop.split('|');
    let propValue;

    for (let propName of displayProps) {
      propValue = rowData.getIn(propName.split('.'));
      if (typeof propValue !== 'undefined') {
        return propValue;
      }
    }

    return rowData.getIn(prop.split('.'), typeof defaultValue !== 'undefined' ? defaultValue : value);
  },

  geoShape({ value, rowData }) {
    return rowData && rowData.has('geoShape') ? sharedGeoShapeSelectors.run(rowData) : value;
  },

  getListOfProperties({rowData, properties}) {
    return fromJS(properties.map(property => ({property, value: rowData.get(property)})));
  },

  toSentenceCase({ value }) {
    let sentenceCaseStrings = [];
    value.toLowerCase().split('_').forEach(val => {
      val.replace(
        /\w\S*/g,
        function (txt) {
          sentenceCaseStrings.push(txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
        }
      );
    });
    return sentenceCaseStrings.join(' ');
  }
};

export default processors;
