import merge from 'lodash/merge';

import coreLocales from '@au/core/lib/locales';

export const defaultEnglish = {
  'au': "Hello, {name}",
  'au.addProperty': '+ Add Property',
  'au.title': "TMC Management Console",
  'au.processing': 'Processing',
  'au.duration': "Duration:",
  'au.dateRange': 'Time Range',
  'au.dateRange.lastNseconds': 'Last {value, plural, one {# second} other {# seconds}}',
  'au.dateRange.lastNminutes': 'Last {value, plural, one {# minute} other {# minutes}}',
  'au.dateRange.lastNhours': 'Last {value, plural, one {# hour} other {# hours}}',
  'au.dateRange.lastNdays': 'Last {value, plural, one {# day} other {# days}}',
  'au.dateRange.lastNmonths': 'Last {value, plural, one {# month} other {# months}}',
  'au.dateRange.monthToDate': 'Month to Date',
  'au.dateRange.prevMonth': 'Previous Month',
  'au.dateRange.quarterN': 'Q{value}',
  'au.createTime': 'Create Time',
  'au.updateTime': 'Update Time',
  'au.calendar.today': 'Today',
  'au.calendar.hint': '* Double click to pick a single date',
  'au.home.title': 'Management Console',
  'au.home.details': 'Details',
  'au.home.taps': 'Taps',
  'au.home.RUNNING': 'Running',
  'au.home.PAUSED': 'Paused',
  'au.home.STOPPED': 'Stopped',
  'au.home.INVALID': 'Not Valid',
  'au.home.INCOMPLETE': 'Not Complete',
  'au.home.number': '{number}',
  'au.home.announcements.more': '+{number} more',
  'au.devPortal': "Dev Portal",
  'au.devPortal.description': "- Explore all the TMC Docs",
  'au.tmcLinks': "TMC Links",
  'au.tmcMetrics': "TMC Metrics",
  'au.tmcMetrics.description': "- TMC Dashboards",
  'au.feedback': "Feedback",
  'au.feedback.description': "- We love feedback!",
  'au.helpCenter': "Help Center",
  'au.helpCenter.description': "- Q&A",
  'au.serviceStatus': 'Service Status',
  'au.serviceStatus.description': '- Autonomic service health',
  'au.noop': " ",
  'au.search': "Search",
  'au.partition': "Partition",
  'au.sidebarSubview': 'Sub Views',
  'au.ftcpVersion': 'FTCP Version: {version}',
  'au.progress': 'Progress',
  'au.notAvailable': 'Not Available',
  'au.cloudToDeviceConversion': 'Cloud to Device Conversion',
  'au.deviceToCloudConversion': 'Device to Cloud Conversion',
  'au.searchList.success.message': 'Successfully loaded {count} {entityName}.',
  'au.searchList.loading.message': 'Loading data - {count} {entityName}. {cancelButton}',
  'au.searchList.partial.message': 'Partial data Load - {count} {entityName}. To search, please {loadMoreButton} data.',
  'au.searchList.cancelled.message': 'Action cancelled. Partial Data Load - {count} {entityName}. To search, please {loadMoreButton} data.',
  'au.searchList.loadAll': 'load all',
  'au.searchList.loadMore': 'load more',
  'au.searchList.cancel': 'Cancel',
  'au.searchList.cancel.inProgress': 'Your data load has been canceled. Please wait while we finalize the process.',
  'au.searchList.continue': 'Continue',
  'au.searchList.error': 'An error occurred while loading data. Please {click}.',
  'au.searchList.filterError': 'An unexpected error occurred and the filter action could not be completed. Please {click} or {reset} all the filters.',
  'au.searchList.tryAgain': 'try again',
  'au.searchList.reset': 'reset',
  'au.noResultsFound': "No results found",
  'au.noDataAvailable': 'No data available',
  'au.noVersionAvailable': 'Version is not available for well known commands.',
  'au.multiEntryEditor.type.placeholder': 'Type',
  'au.multiEntryEditor.maxEntries': '{count, plural, one {One entry is} other {# entries}} entered. The limit is <cta>{limit}</cta> entries.',
  'au.multiEntryEditor.invalidEntries': '{count, plural, one {One entry is} other {# entries are}} not valid',
  'au.multiEntryEditor.separationNote': 'Please use space, comma, or semicolon to separate values',
  'au.pleaseMsg': 'Please {msg}',
  'au.tryAgain': 'try again',
  'au.tryAgainLater': 'try again later',
  'au.updatingWait': "Updating... Please wait...",
  'au.updatingDataWait': "Updating data... Please wait...",
  'au.poweredBy': "Powered by Autonomic LLC",
  'au.select': 'Select',
  'au.deselect': 'Deselect',
  'au.fuelConsumed': "Fuel Consumed",
  'au.distanceTraveled': "Distance Traveled",
  'au.averageEconomy': "Average Economy",
  'au.section.title.access': "Access",
  'au.section.title.assets': "Assets",
  'au.section.title.commands': "Commands",
  'au.section.title.dashboard': "Dashboard",
  'au.section.title.data': "Data",
  'au.section.title.map': "Map",
  'au.section.title.vehicles': "Vehicles",
  'au.section.title.resources': "Resources",
  'au.section.title.overviews': "Overviews",
  'au.section.title.manage': "Manage",
  'au.section.title.fleets': "Fleets",
  'au.section.title.geofences': "Geofences",
  'au.section.title.serviceStatus': "Service Status",
  'au.section.title.reports': "Reports",
  'au.section.title.component_gallery': "Components",
  'au.section.title.component_gallery.entity_framework': "Entity Framework",
  'au.section.title.component_gallery.inputs': "Inputs",
  'au.section.title.component_gallery.formatters': "Formatters",
  'au.section.title.component_gallery.filters': "Filters",
  'au.section.title.component_gallery.navigation': "Navigation",
  'au.section.title.component_gallery.base_components': "Base Components",
  'au.section.title.component_gallery.overview_components': "Overview Components",
  'au.section.title.component_gallery.table_components': "Table Components",
  'au.section.title.logout': "Log Out",
  'au.section.title.swagger': "Swagger",
  'au.section.title.admin': 'Au Admin',
  'au.section.title.tmcTopology': 'TMC Topology',
  'au.section.title.topology': 'Topology',
  'au.section.title.topologyOverview': 'Topology Overview',
  'au.section.title.systemMetrics': 'System Metrics',
  'au.unit.si.dist.full': "kilometers",
  'au.unit.si.dist.abbr': "km",
  'au.unit.si.vol.full': "liters",
  'au.unit.si.vol.abbr': "L",
  'au.unit.si.rate.full': "kilometers per hour",
  'au.unit.si.rate.abbr': "km/h",
  'au.unit.si.econ.full': "kilometers per liter",
  'au.unit.si.econ.abbr': "km/L",
  'au.unit.si.minute.full': "minute",
  'au.unit.si.minute.abbr': "min.",
  'au.unit.si.hour.full': "hour",
  'au.unit.si.hour.abbr': "hr.",
  'au.unit.uscs.dist.full': "miles",
  'au.unit.uscs.dist.abbr': "mi.",
  'au.unit.uscs.vol.full': "gallons",
  'au.unit.uscs.vol.abbr': "gal.",
  'au.unit.uscs.rate.full': "miles per hour",
  'au.unit.uscs.rate.abbr': "mph",
  'au.unit.uscs.econ.full': "miles per gallon",
  'au.unit.uscs.econ.abbr': "mpg",
  'au.unit.uscs.minute.full': "minute",
  'au.unit.uscs.minute.abbr': "min.",
  'au.unit.uscs.hour.full': "hour",
  'au.unit.uscs.hour.abbr': "hr.",
  'au.timezone.utc': 'UTC Timezone',
  'au.timezone.local': 'Local Timezone',
  'au.vehicle.title': "Vehicle",
  'au.vehicle.vin': "VIN",
  'au.vehicle.tenant': "Tenant",
  'au.vehicle.name': "Name",
  'au.vehicle.make': "Make",
  'au.vehicle.model': "Model",
  'au.vehicle.year': "Year",
  'au.vehicle.speed': "Speed",
  'au.vehicle.fuel': "Fuel",
  'au.vehicle.fuelLevel': "Fuel",
  'au.vehicle.odometer': "Odometer",
  'au.vehicle.tags': "Tags",
  'au.vehicle.fleet': "Fleet",
  'au.vehicle.ignitionStatus': "Ignition",
  'au.vehicle.imei': "IMEI",
  'au.vehicle.lastUpdate': "Last Update",
  'au.vehicle.lastUpdated': "Last Updated",
  'au.vehicle.gps': "GPS",
  'au.vehicle.unknownVal': "Unknown",
  'au.vehicle.pidPluggedIn': "PID plugged in",
  'au.vehicle.oilLifeRemaining': "Oil life remaining",
  'au.vehicle.oilLevelStatus': "Oil level status",
  'au.vehicle.locationUncertainty': "Location uncertainty",
  'au.vehicle.lastTimestamp': "Last Timestamp",
  'au.vehicle.telemetryTime': "Telemetry Time",
  'au.vehicle.lastTimestampLoading': "Telemetry is updating... Please wait...",
  'au.vehicle.lastTimestampError': "An error was encountered while loading the last timestamp data. Please <a>try again</a>.",
  'au.vehicle.lastTimestampNoData': "No data has been received",
  'au.vehicle.lastFtcpMessage': 'Last FTCP Message',
  'au.vehicle.lastFtcpMessage.FTCP_INGEST': 'Received',
  'au.vehicle.lastFtcpMessage.FTCP_DECRYPTOR_RAW_DECRYPTED': 'Decrypted',
  'au.vehicle.lastFtcpMessage.FTCP_CONVERTER': 'Converted To TMC',
  'au.vehicle.lastFtcpMessage.stage': 'Stage',
  'au.vehicle.lastFtcpMessage.time': 'Time',
  'au.vehicle.lastFtcpMessage.result': 'Result',
  'au.vehicle.lastFtcpMessage.details': 'Details',
  'au.vehicle.lastFtcpMessage.success': 'Success',
  'au.vehicle.lastFtcpMessage.failure': 'Failure',
  'au.vehicle.lastFtcpMessage.details.ingestSource': 'Ingest Source: {val}',
  'au.vehicle.lastFtcpMessage.details.auIngestMsgId': 'Message Id: {val}',
  'au.vehicle.lastFtcpMessage.details.messageType': 'Message Type: {val}',
  'au.vehicle.lastFtcpMessage.details.rawFtcpMessageName': 'Message Name: {val}',
  'au.vehicle.lastFtcpMessage.details.metricsConverted': 'Metrics Converted: {val}',
  'au.vehicle.lastFtcpMessage.details.messageTimestamp': 'Message Timestamp: {val}',
  'au.vehicle.lastFtcpMessage.details.ftcpVersion': 'Version: {val}',
  'au.vehicle.lastFtcpMessage.details.busArch': 'busArch: {val}',
  'au.vehicle.lastFtcpMessage.details.canDB': 'canDB: {val}',
  'au.vehicle.lastFtcpMessage.loading': "Last FTCP Message is updating... Please wait...",
  'au.vehicle.lastFtcpMessage.error': "An error was encountered while loading the last FTCP message data. Please <a>try again</a>",
  'au.vehicle.idleStatus': "Idle status",
  'au.vehicle.currentStatus': 'Current Status',
  'au.vehicle.currentDriver': 'Current Driver',
  'au.vehicle.currentLocation': "Current Location",
  'au.vehicle.geofence': 'Geofence',
  'au.vehicle.driver': 'Driver',
  'au.vehicle.contactDriver': 'Contact',
  'au.vehicle.addNew': 'Create Vehicle',
  'au.vehicle.edit': 'Edit Vehicle',
  'au.vehicle.emptyProperty': 'Please add a property name.',
  'au.vehicle.addTag': "Add Tag",
  'au.vehicle.note': 'Note',
  'au.vehicle.vehicleInfo': 'Vehicle Info',
  'au.vehicle.vehicleDetails': 'Vehicle Details',
  'au.vehicle.vehicleStatus': "Vehicle Status",
  'au.vehicle.statsTitle': "Vehicle Metrics",
  'au.vehicle.section.title.metrics': "Overview",
  'au.vehicle.section.title.analytics': "Analytics",
  'au.vehicle.section.title.alerts': "Events",
  'au.vehicle.section.title.geofences': "Geofences",
  'au.vehicle.manageBindings': 'Manage Bindings',
  'au.vehicle.noBindingsYet': 'There are no Bindings yet',
  'au.vehicle.bindings.deviceType': 'Device Type',
  'au.vehicle.bindings.device': 'Device',
  'au.vehicle.bindings.device.hint': '(Serial, ESN or IMEI)',
  'au.vehicle.bindings.action': 'Action',
  'au.vehicle.bindings.add': 'Add Binding',
  'au.vehicle.bindings.add.save': 'Save',
  'au.vehicle.bindings.add.cancel': 'Cancel',
  'au.vehicle.bindings.add.saving': 'Saving...',
  'au.vehicle.bindings.deviceIdOrEsn': 'Device Id or ESN',
  'au.vehicle.bindings.add.placeholder': 'Type or paste device Id or ESN',
  'au.vehicle.bindings.delete': 'Delete Binding',
  'au.vehicle.bindings.delete.confirmation': 'Are you sure to delete?',
  'au.vehicle.bindings.delete.proceed': 'Proceed',
  'au.vehicle.bindings.delete.cancel': 'Cancel',
  'au.vehicle.bindings.deleting': 'Deleting...',
  'au.vehicle.bindings.deviceDetails': 'Device {detail}',
  'au.vehicle.bindings.currentlyBoundDevices': 'Currently Bound Devices',
  'au.vehicle.tapFilters.iamMessage': 'Taps with IAM Filters are not currently captured in the results',
  'au.vehicles.title.createVehicle': 'Create Vehicle',
  'au.vehicles.title.editVehicle': 'Edit Vehicle',
  'au.vehicles.title': 'Vehicles',
  'au.vehicles.title.overview': 'Overview',
  'au.vehicles.title.list': 'List',
  'au.vehicles.title.analytics': 'Analytics',
  'au.vehicles.title.management': 'Management',
  'au.vehicles.filterPlaceholder': "Filter Vehicles",
  'au.vehicles.searchboxPlaceholder': "Search Vehicles",
  'au.vehicles.createVehicle': 'Create Vehicle',
  'au.vehicles.clearHistory': 'Clear History',
  'au.vehicles.lookupPlaceholder': 'Type or paste vehicle Id or VIN',
  'au.vehicles.lookupVinPlaceholder': 'Lookup VIN',
  'au.vehicles.lookupEmbeddedVehiclePlaceholder': 'Look up vehicle Id or VIN',
  'au.vehicles.vehicleVin': 'Vehicle VIN',
  'au.vehicles.vehicleIdOrVin': 'Vehicle Id or VIN',
  'au.vehicles.successText': 'We successfully loaded {count, plural, one {# vehicle} other {# vehicles}}.',
  'au.vehicles.properties.namePlaceholder': 'Type or choose property name',
  'au.vehicles.properties.valuePlaceholder': 'Type property value',
  'au.vehicles.properties.remove': 'Remove',
  'au.device.deviceId': 'Device Id',
  'au.device.bindDevice': 'Bind Device',
  'au.device.unbindDevice': 'Unbind Device',
  'au.device.unbind.title': 'Unbind Device',
  'au.device.unbind.confirm': 'Yes, Unbind the Device',
  'au.device.unbind.message1': 'This action unbinds the device from a vehicle resource.',
  'au.device.unbind.message2': 'Are you sure you want to proceed?',
  'au.device.alert.unbindSuccess.title': 'Success',
  'au.device.alert.unbindSuccess.msg': 'Device was successfully unbinded',
  'au.devices.noDeviceFoundWithId': 'No device found with Id or ESN matching "{deviceId}"',
  'au.devices.deviceType': 'Device Type',
  'au.devices.deviceIdOrEsn': 'Device Id or ESN',
  'au.devices.lookupPlaceholder': 'Type or paste device Id or ESN',
  'au.devices.types.epid2': 'epid2',
  'au.devices.types.iwid': 'iwid',
  'au.devices.types.tbox': 'tbox',
  'au.devices.types.tbox3': 'tbox3',
  'au.devices.types.tcu': 'tcu',
  'au.devices.types.ecg': 'ecg',
  'au.devices.types.geotab': 'geotab',
  'au.devices.types.meb-ocu4': 'meb-ocu4',
  'au.devices.types.ocu3': 'ocu3',
  'au.devices.types.tcu-fnv2': 'tcu-fnv2',
  'au.devices.types.tcu-fb0': 'tcu-fb0',
  'au.devices.types.lytx': 'lytx',
  'au.devices.types.fnv4': 'fnv4',
  'au.devices.types.all-devices': 'All devices (.*)',
  'au.deviceConnectivity.state.DISCONNECTED': 'Disconnected',
  'au.deviceConnectivity.state.CONNECTED': 'Connected',
  'au.deviceConnectivity.state.DEEP_SLEEP': 'Deep Sleep',
  'au.deviceConnectivity.state.UNKNOWN': 'Unknown',
  'au.deviceConnectivity.noDataAvailable': 'No Data Available',
  'au.device.deviceConnectivityLoading': 'The device connectivity data is loading.... Please wait...',
  'au.device.deviceConnectivityError': 'An error was encountered while loading the device connectivity data. Please <a>try again</a>.',
  'au.devices.successText': 'We successfully loaded {count, plural, one {# device} other {# devices}}.',
  'au.devices.lookupEmbeddedDevicePlaceholder': 'Look up ESN or Id',
  'au.resources': '',
  'au.alert.time': "Time",
  'au.alert.message': "Message",
  'au.alert.vin': "VIN",
  'au.alerts.filterPlaceholder': "Filter Events",
  'au.asset.alerts.filterPlaceholder': "Filter Events",
  'au.table.dataEmptyMessage': "Looks like there's nothing to display at the moment.",
  'au.table.dataFetchingMessage': "Hold on, we're fetching the data",
  'au.table.filterEmptyMessage': "Looks like there's nothing matching your search filter.",
  'au.table.dataFilterEmptyMessage': "Nothing to display. Please change filters or search input.",
  'au.analytics.fuelChart.title': "Fleet Fuel Consumed",
  'au.analytics.distChart.title': "Fleet Distance Traveled",
  'au.analytics.econChart.title': "Fleet Average Economy",
  'au.analytics.distAvgChart.title': "Dist. Traveled per Vehicle",
  'au.geofence.name': 'Name',
  'au.geofence.address': 'Address',
  'au.geofence.vehiclesCount': '{count, number} {count, plural, one {Vehicle} other {Vehicles}}',
  'au.geofence.numVehicles': '# of Current Vehicles',
  'au.geofencepage.table.vinLabel': 'Vehicle Name',
  'au.geofencepage.backlink': "Back to list",
  'au.geofencepage.filterPlaceholder': "Search by VIN",
  'au.geofencepage.searchboxPlaceholder': "Search by VIN",
  'au.geofence.deleteWarning': "Are you sure you want to delete this Geofence?",
  'au.geofence.editor.draw': 'Draw',
  'au.geofence.editor.polygon': 'Polygon',
  'au.geofence.editor.rectangle': 'Rectangle',
  'au.geofence.editor.circle': 'Circle',
  'au.geofence.editor.move': 'Move',
  'au.geofence.editor.reset': 'Clear',
  'au.geofence.editor.map': 'Map',
  'au.geofence.editor.satellite': 'Satellite',
  'au.geofence.editor.autocomplete.placeholder': 'Search for an address',
  'au.geofence.editor.name.placeholder': 'Type a geofence name',
  'au.geofence.editor.fleet': 'Fleet Name',
  'au.geofence.editor.edit': 'Edit Geofence',
  'au.geofence.editor.new': 'Create New Geofence',
  'au.geofence.editor.createBtn': 'Create Geofence',
  'au.geofence.editor.instructions': "Click a drawing tool to draw a shape on the map",
  'au.geofence.saveError': 'An error occurred trying to save the Geofence.',
  'au.geofence.saveNoGeofenceError': 'A Geofence region is needed to save',
  'au.geofences.filterPlaceholder': 'Search Geofence',
  'au.geofences.searchboxPlaceholder': 'Search Geofence',
  'au.geofences.addNew': 'Create Geofence',
  'au.geofences.title.createGeofence': "Create Geofence",
  'au.geofences.title': "Geofences",
  'au.geofences.noHistory': "Looks like this vehicle has no Geofence history",
  'au.geofences.mostFrequent': 'Frequently Visited',
  'au.geofences.mostRecent': 'Most Recent',
  'au.geofences.list': "List",
  'au.geofences.map': "Map",
  'au.asset.analytics.fuelChart.title': "Fuel Consumed",
  'au.asset.analytics.distChart.title': "Distance Traveled",
  'au.asset.analytics.econChart.title': "Average Economy",
  'au.asset.indicatorsTable.header.indicators': "Indicators",
  'au.asset.indicatorsTable.header.status': "Status",
  'au.asset.indicatorsTable.collisionWarning': "Collision warning",
  'au.asset.indicatorsTable.lowWasherFluid': "Low washer fluid",
  'au.asset.indicatorsTable.dieselExhaustFluid': "Diesel exhaust fluid",
  'au.asset.indicatorsTable.tractionControl': "Traction control",
  'au.asset.indicatorsTable.engineWarning': "Engine warning",
  'au.asset.indicatorsTable.airbag': "Airbag",
  'au.asset.indicatorsTable.waterInFuel': "Water in fuel",
  'au.asset.indicatorsTable.autoSpeedLimiter': "Automatic speed limiter",
  'au.asset.indicatorsTable.ignition': "Ignition",
  'au.asset.indicatorsTable.abs': "ABS",
  'au.asset.indicatorsTable.oilPressure': "Oil pressure",
  'au.asset.indicatorsTable.brakeSystem': "Brake system",
  'au.asset.indicatorsTable.engineCoolantTemp': "Engine coolant temperature",
  'au.asset.indicatorsTable.laneDeparture': "Lane departure",
  'au.asset.indicatorsTable.tirePressureRearOuterLeft': "Tire pressure : Rear outer left",
  'au.asset.indicatorsTable.tirePressureRearOuterRight': "Tire pressure : Rear outer right",
  'au.asset.indicatorsTable.tirePressureFrontLeft': "Tire pressure : Front left",
  'au.asset.indicatorsTable.tirePressureFrontRight': "Tire pressure : Front right",
  'au.asset.indicatorsTable.tirePressureRearLeft': "Tire pressure : Rear left",
  'au.asset.indicatorsTable.tirePressureRearRight': "Tire pressure : Rear right",
  'au.geofence.direction': "Event Type",
  'au.geofence.eventTime': "Time",
  'au.geofence.enterTime': "Enter Time",
  'au.geofence.exitTime': "Exit Time",
  'au.geofence.entries': "{count, number} {count, plural, one {time} other {times}} / last 7 days",
  'au.geofence.duration': "Duration",
  'au.label.menu': "Menu",
  'au.label.close': "Close",
  'au.page.create': 'Create',
  'au.page.back': 'Back',
  'au.page.save': 'Save',
  'au.page.cancel': 'Cancel',
  'au.time.oneWeek': "1 Week",
  'au.time.twoWeeks': "2 Weeks",
  'au.time.oneMonth': "1 Month",
  'au.time.twoMonths': "2 Months",
  'au.time.lastNhours': 'Last {value, plural, one {# hour} other {# hours}}',
  'au.time.lastNdays': 'Last {value, plural, one {# day} other {# days}}',
  'au.yes': "Yes",
  'au.no': "No",
  'au.on': "On",
  'au.off': "Off",
  'au.false': "False",
  'au.true': "True",
  'au.NA': "N/A", // not available
  'au.normal': "Normal",
  'au.disable': "Disable",
  'au.default': "Default",
  'au.lasr-input-kafka': "lasr-input-kafka",
  'au.lasr-input-gcp': "lasr-input-gcp",
  'au.GCP': "GCP",
  'au.Kafka': "Kafka",
  'au.default.not': "-",
  'au.multiselect.placeholder': "Drop down or Type",
  'au.filters.numVehicles': "{count, plural, one {1 Vehicle} other {# Vehicles}}",
  'au.filters.tooMany': "{count, plural, one {1 Filter} other {# Filters}}",
  'au.filters.muzzledWarning': "No vehicles match your filter criteria",
  'au.filters.resetWarning': "Are you sure you want to remove all filters?",
  'au.filters.confirmResetButton': "Yes, Remove All",
  'au.filters.addFilters': "Add Filters",
  'au.filters.currentFilters': "Current Filters",
  'au.filters.vin': "VIN",
  'au.filters.imei': "IMEI",
  'au.filters.fleet': "Fleet",
  'au.filters.fleetID': "Fleet ID",
  'au.filters.pidStatus': "PID Status",
  'au.filters.model': "Model",
  'au.filters.make': "Make",
  'au.filters.year': "Year",
  'au.filters.alerts': "Events",
  'au.filters.unknown': "Unknown",
  'au.filters.vinFilters': "VIN Filters",
  'au.filters.imeiFilters': "IMEI Filters",
  'au.filters.fleetFilters': "Fleet Filters",
  'au.filters.fleetIdFilters': "Fleet ID Filters",
  'au.filters.pidStatusFilters': "PID Status Filters",
  'au.filters.modelFilters': "Model Filters",
  'au.filters.makeFilters': "Make Filters",
  'au.filters.yearFilters': "Year Filters",
  'au.filters.alertsFilters': "Event Filters",
  'au.filters.unknownFilters': "Unknown Filters",
  'au.filters.reset': "Remove All filters",
  'au.filters.cancel': "Cancel",
  'au.filterSegment.clear': 'Clear',
  'au.filters.conditionAnd': "Filter Condition: AND",
  'au.filters.conditionOr': "Filter Condition: OR",
  'au.filters.apply': "Apply",
  'au.filters.keyValue.key': "Type name",
  'au.filters.keyValue.value': "Type value",
  'au.filters.property.key': "Type or choose a property name",
  'au.filters.property.value': "Type property value",
  'au.filters.reportedProperty.key': "Type property name",
  'au.filters.tag.key': "Type tag name",
  'au.filters.tag.value': "Type tag value",
  'au.filters.timePeriod.local': "(Local Timezone)",
  'au.filters.timePeriod.UTC': "(UTC Timezone)",
  'au.filters.timePeriod.start': "Start Time",
  'au.filters.timePeriod.startDescription': "Start time is greater than or equal to a time set value.",
  'au.filters.timePeriod.startSymbol': "Start >=",
  'au.filters.timePeriod.end': "End Time",
  'au.filters.timePeriod.endDescription': "End time is less than a set value.",
  'au.filters.timePeriod.endSymbol': "End <",
  'au.filters.dateRange.custom': "Custom Range",
  'au.filters.dateRange.select': 'Select Range',
  'au.filter.dateRange.preset': 'Preset Ranges',
  'au.filters.placeholders.deviceType': "Select a device type",
  'au.filters.placeholders.versions': "#.#.#",
  'au.filters.placeholders.vehicleModel': "[\"F-150\", \"Nautilus\", \"Mustang\"]",
  'au.filters.placeholders.vehicleModelYear': "[\"2022\", \"2020\", \"2018\"]",
  'au.filters.placeholders.subjectUser': 'Type user name',
  'au.filters.placeholders.subjectUserGroup': 'Type user group id',
  'au.filters.placeholders.subjectClient': 'Type client id',
  'au.filters.placeholders.resource': 'Type aui',
  'au.dashboard.stat.total': 'Total #',
  'au.dashboard.stat.service': 'Need Service',
  'au.dashboard.stat.ignitionOff': 'Ignition Off',
  'au.dashboard.stat.idling': 'Idling',
  'au.dashboard.stat.driving': 'Driving',
  'au.dashboard.stat.utilization': 'Utilization',
  'au.dashboard.miniCharts.today': 'Today',
  'au.dashboard.tile.header.totalVehicles': "Total Vehicles",
  'au.dashboard.tile.header.totalFleets': "Total Fleets",
  'au.dashboard.tile.header.totalGeofences': "Total Geofences",
  'au.dashboard.tile.header.assignedDevices': "Assigned Devices",
  'au.dashboard.tile.header.assignedVehicles': "Assigned Vehicles",
  'au.dashboard.tile.header.unpluggedVehicles': "Unplugged Vehicles",
  'au.dashboard.tile.link.manage': "Manage",
  'au.dashboard.tile.link.viewList': "View List",
  'au.dashboard.fleetBarChart.title': "Vehicle Assignments",
  'au.searchbox.placeholder': "Filter",
  'au.searchbox.moreMatching': "{number} More {category} matching",
  'au.searchbox.disabledHint': 'Search is disabled since not all the records are loaded',
  'au.reverseGeocode.isFetching': "Fetching...",
  'au.reverseGeocode.undetermined': "Undetermined",
  'au.validation.isRequired': '{field} is required',
  'au.validation.areRequired': '{field} are required',
  'au.validation.notValid': '{field} is not valid',
  'au.validation.notValidUsername': 'Is not a valid username',
  'au.validation.notValidUUID': '{field} is not a valid uuid.',
  'au.validation.passwordMismatch': "Passswords don't match",
  'au.validation.minLength': '{field} must be at least {length} characters long',
  'au.validation.maxLength': '{field} must be at most {length} characters long',
  'au.validation.exactLength': '{field} must be {length} characters long',
  'au.validation.minNumber': '{field} must be at least {num}',
  'au.validation.maxNumber': '{field} must be at most {num}',
  'au.validation.duplicateEntry': 'Duplicate entry',
  'au.validation.duplicateEntryWontBeAdded': "It won't be added to the list",
  'au.validation.duplicateTag': 'Duplicate Tag Name. Please enter unique tag.',
  'au.validation.duplicateVin': 'Duplicate Vin. Please enter unique Vin.',
  'au.validation.duplicateProperty': 'Duplicate Property name. Please enter unique name.',
  'au.validation.invalidProperty': 'Invalid Property name. Please select one from the list.',
  'au.validation.invalidClientName': '{field} is not valid. It can contain lowercase letters, numbers, /, _ or -',
  'au.validation.invalidClientDisplayName': '{field} is not valid. It can contain letters, numbers, /, _ or -',
  'au.validation.invalidTagName': '{field} is not valid. It can contain letters, numbers or _',
  'au.validation.invalidTagValue': '{field} is not valid. It must match the pattern {re}',
  'au.validation.invalidFlowName': '{field} is not valid. It can contain lowercase letters, numbers, / or -',
  'au.validation.invalidForkName': '{field} is not valid. It can contain lowercase letters, numbers, / or -',
  'au.validation.invalidTapName': '{field} is not valid. It can contain lowercase letters, numbers, / or -',
  'au.validation.invalidProcessorName': '{field} is not valid. It can contain lowercase letters, numbers, / or -',
  'au.validation.invalidUserGroupName': '{field} is not valid. It can contain lowercase letters, numbers, / or -',
  'au.validation.invalidUserGroupTag': '{field} is not valid. It can contain lowercase letters, numbers, / or -',
  'au.validation.invalidCharacter': '{field} is not valid. It can contain {validCharacters}',
  'au.validation.invalidDate': 'Invalid Date',
  'au.validation.incompleteDate': 'Please enter full date',
  'au.validation.incompleteTime': 'Please enter full time',
  'au.validation.invalidTime': 'Invalid Time',
  'au.validation.invalidCommandVersion': 'Must be a whole number 0-99',
  'au.validation.laterDate': 'Please enter later date',
  'au.validation.earlierDate': "Please enter earlier date",
  'au.validation.beforeDate': 'The {field} must be {inclusive, plural, one {equal to or} other {}} before the {target}',
  'au.validation.afterDate': 'The {field} must be {inclusive, plural, one {equal to or} other {}} after the {target}',
  'au.validation.pattern': '{field} must match the pattern {re}',
  'au.validation.duplicateInputFlow': 'Input flow cannot be the same as Output flow.',
  'au.validation.duplicateOutputFlow': 'Output flow cannot be the same as Input flow.',
  'au.validation.invalidCommandType': 'Please enter a {field} in a valid format. (Only letters and numbers. No special characters)',
  'au.service': 'Service',
  'au.service.entities': 'Entities',
  /* NOTE: au.entity.* locales are constructed dynamically  */
  'au.entity.fields': 'Fields',
  'au.entity.days': 'Days',
  'au.entity.hours': 'Hours',
  'au.entity.minutes': 'Minutes',
  'au.entity.seconds': 'Seconds',
  'au.entity.manage': 'Manage',
  'au.entity.register': 'Register',
  'au.entity.save': 'Save Changes',
  'au.entity.start': 'Start',
  'au.entity.unbound': 'Unbound',
  'au.entity.bound': 'Bound',
  'au.entity.enabled': 'Enabled',
  'au.entity.bind': 'Bind',
  'au.entity.unbind': 'Unbind',
  'au.entity.unknown': 'Unknown',
  'au.entity.unknown_event': 'Unknown',
  'au.entity.save.wait': 'Saving...',
  'au.entity.saveError': 'An error occurred trying to save.',
  'au.entity.create': 'Create',
  'au.entity.create.wait': 'Creating...',
  'au.entity.edit': 'Edit',
  'au.entity.viewHistory': 'View History',
  'au.entity.manageBindings': 'Manage Bindings',
  'au.entity.autonomicStatusPage': 'Autonomic Status Page',
  'au.entity.migrate.statusPage': 'This Service Status page will be replaced by the {name}. Listed items on this page will no longer be supported after 01/08/2023.',
  'au.entity.back': 'Back',
  'au.entity.viewDetails': 'View Details',
  'au.entity.a.overview': 'Overview',
  'au.entity.reset': 'Reset',
  'au.entity.retry': 'Retry',
  'au.entity.close': 'Close',
  'au.entity.cancel': 'Cancel',
  'au.entity.duplicate': 'Duplicate',
  'au.entity.proceed': 'Proceed',
  'au.entity.replicate': 'Replicate',
  'au.entity.replicate.message': 'Choose a destination partition to replicate the configurations.',
  'au.entity.replicate.currentPartition': 'Current Partition',
  'au.entity.replicate.destinationPartition': 'Destination Partition',
  'au.entity.delete': 'Delete',
  'au.entity.delete.confirmation': 'Delete this {entity}?',
  'au.entity.delete.success.name': 'Deleted {entity}: <b>{entityName}</b>',
  'au.entity.delete.success.message': 'The {entity} was successfully deleted.',
  'au.entity.delete.wait': 'Deleting...',
  'au.entity.delete.confirm': 'Yes, Delete',
  'au.entity.disable': 'Disable',
  'au.entity.displayName': 'Display Name',
  'au.entity.confirm': 'Confirm',
  'au.entity.noUndo': 'This action cannot be undone.',
  'au.entity.remove': 'Remove',
  'au.entity.remove.confirm': 'Yes, Remove',
  'au.entity.details': 'Details',
  'au.entity.history': 'History',
  'au.entity.update': 'Update',
  'au.entity.validation': 'Validation',
  'au.entity.policies': 'Policies',
  'au.entity.list': '{entityName} List',
  'au.entity.id': '{entityName} Id',
  'au.entity.prefix.user': 'aui:iam:user/',
  'au.entity.prefix.userGroup': 'aui:iam:userGroup/',
  'au.entity.prefix.service.account': 'aui:iam:user/service-account-',
  'au.entity.taps.filterType': 'Filter Type',
  'au.entity.taps.details': 'Details',
  'au.entity.taps.detailsHint': 'You have leading or trailing spaces in the value which may affect the data flow.',
  'au.entity.taps.permitDeny': 'Permit / Deny',
  'au.entity.taps.sequence': 'Seq.',
  'au.entity.taps.more': '+ {number} more',
  'au.entity.taps.validation.details.filterConfiguration': 'Filter Configuration',
  'au.entity.taps.validation.details.topologyValidation': 'Topology Validation',
  'au.entity.taps.validation.validate': ' Validate',
  'au.entity.taps.validation.jsonViewer': 'JSON Viewer',
  'au.entity.taps.validation.showInvalidOnly': 'Show invalid only',
  'au.entity.taps.validation.expandAll': 'Expand All',
  'au.entity.taps.validation.collapseAll': 'Collapse All',
  'au.entity.taps.validation.topology.message.success': '<b>Valid</b> - It is successfully configured and there is no conflict with upstream taps or forks to emit telemetry data',
  'au.entity.taps.validation.topology.message.invalid': '<b>Invalid</b> - One or more filter conflicts were detected with upstream taps or forks.',
  'au.entity.taps.validation.topology.message.invalid.withLink': '<b>Invalid</b> - One or more filter conflicts were detected. See validation details <a>here</a>',
  'au.entity.taps.validation.topology.message.validating': '<b>Validation in progress</b> - It is checking the validation against upstream tap/fork filter configurations',
  'au.entity.taps.validation.topology.message.info': '<b>Note</b> - A properly configured tap may have conflicts with upstream taps or forks. Please run the validation check to make sure it emits telemetry data.',
  'au.entity.taps.validation.topology.message.info.withLink': '<b>Note</b> - A properly configured tap may have conflicts with upstream taps or forks. Please <a>run the validation</a> check to make sure it emits telemetry data.',
  'au.entity.taps.validation.topology.filterDetails.headers.sequence': 'Seq.',
  'au.entity.taps.validation.topology.filterDetails.headers.filterType': 'Filter Type',
  'au.entity.taps.validation.topology.filterDetails.headers.validity': 'Validity',
  'au.entity.taps.validation.topology.filterDetails.headers.name': 'Name/Id',
  'au.entity.taps.validation.topology.filterDetails.headers.validationStatus': 'Validation Status',
  'au.entity.taps.validation.topology.filterDetails.headers.validationStatus.valid': 'Permitted',
  'au.entity.taps.validation.topology.filterDetails.headers.validationStatus.invalid': 'Not Permitted',
  'au.entity.taps.validation.topology.filterDetails.headers.validationStatus.warning': 'Warning',
  'au.entity.taps.validation.topology.filterDetails.headers.validationStatus.unknown': 'Unknown',
  'au.entity.taps.validation.topology.filterDetails.filterTypes.GROUP': 'Group',
  'au.entity.taps.validation.topology.filterDetails.filterTypes.VIN': 'VIN',
  'au.entity.taps.validation.topology.filterDetails.filterTypes.ATTRIBUTE': 'Attribute',
  'au.entity.taps.validation.topology.filterDetails.titles.fork': 'Fork',
  'au.entity.taps.validation.topology.filterDetails.titles.output': 'Output',
  'au.entity.taps.validation.topology.filterDetails.titles.filterDetails': 'Filter Details',
  'au.entity.taps.validation.topology.filterDetails.titles.entityDetails': '{entityName} Details',
  'au.entity.taps.validation.topology.filterDetails.titles.type': 'Type',
  'au.entity.taps.validation.topology.filterDetails.titles.permit': 'Permit/Deny',
  'au.entity.taps.validation.topology.filterDetails.titles.vin': 'Vin Info',
  'au.entity.taps.validation.topology.filterDetails.titles.tapsForks': 'Taps/Forks',
  'au.entity.taps.validation.topology.filterDetails.titles.filters': 'Filters',
  'au.entity.taps.validation.topology.filterDetails.titles.validationStatus': 'Validation Status',
  'au.entity.taps.validation.topology.filterDetails.titles.testSequence': 'Test Sequence',
  'au.entity.taps.validation.topology.filterDetails.titles.vins': 'VINs',
  'au.entity.taps.validation.topology.filterState.VALID': 'Valid',
  'au.entity.taps.validation.topology.filterState.INVALID': 'Invalid',
  'au.entity.taps.validation.topology.filterState.WARNING': 'Warning',
  'au.entity.taps.validation.topology.filterState.unknown': 'Unknown',
  'au.entity.state.RUNNING': "Running",
  'au.entity.state.PAUSING': "Pausing",
  'au.entity.state.PAUSED': "Paused",
  'au.entity.state.STARTING': "Starting",
  'au.entity.state.STOPPING': "STOPPING",
  'au.entity.state.REPLAY': "Replaying",
  'au.entity.state.STOPPED': "Stopped",
  'au.entity.state.unknown': "Unknown",
  'au.entity.state.paused.POINT_OF_PAUSE': "It will resume at the point where it was paused. It will automatically unpause after one hour.",
  'au.entity.state.paused.LATEST': "It will resume at the latest data point.",
  'au.entity.state.paused.default': "It will resume at the latest data point.",
  'au.entity.state.EXPIRED': 'Expired',
  'au.entity.state.SUCCESS': 'Success',
  'au.entity.state.FAILURE': 'Failure',
  'au.entity.state.CANCELLED': 'Canceled',
  'au.entity.state.DEVICE_RESPONSE_RECEIVED': 'Response Received',
  'au.entity.state.IN_FLIGHT': 'In Flight',
  'au.entity.state.RECEIVED_USER_INPUT_ALLOW': 'User Allowed',
  'au.entity.state.RECEIVED_USER_INPUT_DENY': 'User Denied',
  'au.entity.state.REQUESTED': 'Requested',
  'au.entity.state.REQUEST_DELIVERY_IN_PROGRESS': 'Request Delivery in Progress',
  'au.entity.state.IN_PROGRESS': 'IN PROGRESS',
  'au.entity.state.INVALID': 'Not Valid',
  'au.entity.state.INCOMPLETE': 'Not Complete',
  'au.entity.state.VALID': 'Valid',
  'au.entity.state.ALLOW': 'Allow',
  'au.entity.state.DENY': 'Deny',
  'au.entity.state.DEEP_SLEEP': 'Deep Sleep',
  'au.entity.state.CONNECTED': 'Connected',
  'au.entity.state.DISCONNECTED': 'Disconnected',
  'au.entity.state.NOT_BOUND': 'Unbound',
  'au.entity.state.BOUND': 'Bound',
  'au.entity.state.PARTIAL_SUCCESS': 'PARTIAL SUCCESS',
  'au.entity.state.PARTIAL_FAILURE': 'PARTIAL FAILURE',
  'au.entity.device.serial': 'Serial',
  'au.entity.name': 'Name',
  'au.entity.vins': 'VINs',
  'au.entity.value': 'Value',
  'au.entity.subject': 'Subject',
  'au.entity.success': 'Success',
  'au.entity.role': 'Role',
  'au.entity.group': 'Group ID',
  'au.entity.more': '+{number} more',
  'au.entity.viewTopology': 'View in Topology',
  'au.entity.validationCheck': 'Validation Check',
  'au.entity.connectivityStatus': 'Connectivity Status',
  'au.entity.provisionStatus': 'Provision Status',
  'au.entity.notProvisioned': 'Not Provisioned',
  'au.entity.noProvisionedDevice': 'No provisioned device',
  'au.entity.connected': 'Connected',
  'au.entity.notConnected': 'Not Connected',
  'au.entity.disconnected': 'Disconnected',
  'au.entity.provisionedDevices': 'Provisioned Devices',
  'au.entity.lastFeedEvent': 'Last Feed Event',
  'au.entity.commandManagement.definition.sequence': 'Seq.',
  'au.entity.commandManagement.definition.type': 'Type',
  'au.entity.attr.deviceTypePriorityList': 'Device Priority List',
  'au.entity.attr.deliveryPriority': 'Delivery Priority',
  'au.entity.attr.Output': 'Output',
  'au.entity.attr.pauseTap': 'Pause Tap',
  'au.entity.attr.pauseFork': 'Pause Fork',
  'au.entity.commandManagement.actions.update': 'Update',
  'au.entity.commandManagement.actions.publish': 'Publish',
  'au.entity.commandManagement.actions.delete': 'Delete',
  'au.entity.commandManagement.actions.changeStatus': 'Change Status',
  'au.entity.commandManagement.actions.duplicate': 'Duplicate',
  'au.entity.commandManagement.definition.drafts': '{drafts} drafts',
  'au.entity.commandManagement.definition.version': 'Version',
  'au.entity.commandManagement.definition.versions': '{versions} versions',
  'au.entity.commandManagement.definition.validity': 'Validity',
  'au.entity.commandManagement.duplicate': 'Duplicate Custom Command',
  'au.entity.commandManagement.command': 'Command',
  'au.entity.commandManagement.response': 'Response',
  'au.entity.commandManagement.propertySchema': 'Property Schema',
  'au.entity.commandManagement.continue': 'Continue',
  'au.entity.commandManagement.cancel': 'Cancel',
  'au.entity.commandManagement.exit': 'Exit',
  'au.entity.commandManagement.requestPayload': 'Request Payload',
  'au.entity.commandManagement.ftcpCloudToDeviceMessage': 'FTCP Cloud To Device Message: {response}',
  'au.entity.commandManagement.ftcpDeviceToCloudMessage': 'FTCP Device To Cloud Message Response: {response}',
  'au.entity.commandManagement.commandResponse': 'Command Response: {response}',
  'au.entity.commandManagement.correlatedAlert': 'Correlated Alert: {alert}',
  'au.entity.commandManagement.commandName': 'Command Name: {name}',
  'au.entity.commandManagement.ftcpVersion': 'FTCP Version: {version}',
  'au.entity.commandManagement.alert': 'Alert',
  'au.entity.commandManagement.create': 'Create',
  'au.entity.commandManagement.requestPayloadSample': 'Request Payload Sample',
  'au.entity.commandManagement.ftcpStructure': 'FTCP Structure',
  'au.entity.commandManagement.copyToClipboard': 'Copy to Clipboard',
  'au.entity.commandManagement.maxExpirationSeconds': 'Max 30 days, Min 30 secs',
  'au.entity.commandManagement.ftcpMessage': 'FTCP Oracle does not include query/query responses',
  'au.entity.commandManagement.convertedAlertMessage': 'Converted alert may not be available on TMC. The event presence on TMC depends on whether the vehicle is configured to and capable of sending it.',
  'au.entity.goToCommandList': 'Go to Commands',
  'au.entity.commandManagement.viewjson': 'View JSON',
  'au.entity.commandManagement.publish': 'Publish',
  'au.entity.commandManagement.delete': 'Delete',
  'au.entity.commandManagement.tmcSampleAPIRequest': 'TMC Sample API Request',
  'au.entity.commandManagement.tmcResponsePayload': 'TMC Response Payload',
  'au.entity.commandManagement.commandDefinition': 'Command Definition',
  'au.entity.commandManagement.metadata': 'Metadata',
  'au.entity.commandManagement.commandDefinition.state.DRAFT': 'Draft',
  'au.entity.commandManagement.commandDefinition.state.ENABLED': 'Enabled',
  'au.entity.commandManagement.commandDefinition.state.DISABLED': 'Disabled',
  'au.entity.commandManagement.commandDefinition.validity.true': 'Valid',
  'au.entity.commandManagement.commandDefinition.validity.false': 'Not Valid',
  'au.entity.commandManagement.customCommandDefinition': 'Custom Command Definition',
  'au.entity.commandManagement.type': 'Type:',
  'au.entity.commandManagement.name': 'Name:',
  'au.entity.commandManagement.version': 'Version:',
  'au.entity.commandManagement.fsmName': 'FSM:',
  'au.entity.commandManagement.description': 'Description:',
  'au.entity.commandManagement.importJson': 'You may import a JSON file to prefill the forms below.',
  'au.entity.commandManagement.import': 'Import',
  'au.entity.commandManagement.importDefinition': 'Import Custom Command Definition',
  'au.entity.commandManagement.importNotice': 'Please note that it will overwrite information and no validation is available for the imported JSON code.',
  'au.entity.commandManagement.importError': 'Nothing to import.',
  'au.entity.commandManagement.maxExpiration': 'Max {maxDays, plural, one {1 day} other {# days}} Min {minSeconds, plural, one {1 second} other {# seconds}}',
  'au.entity.commandManagement.viewInJsonViewer': 'View in JSON Viewer',
  'au.entity.commandManagement.define': 'Define',
  'au.entity.commandManagement.cloudToDeviceDefs': 'Cloud to Device Conversion Definitions',
  'au.entity.commandManagement.deviceToCloudDefs': 'Device to Cloud Conversion Definitions',
  'au.entity.commandManagement.deliveryPriority': 'Delivery Priority',
  'au.entity.commandManagement.timeTooHigh': 'Please enter a time less than or equal to {maxExpirationDays, plural, one {1 day} other {# days}}',
  'au.entity.commandManagement.timeTooLow': 'Please enter a time greater than or equal to {minExpirationSeconds, plural, one {1 second} other {# seconds}}',
  'au.entity.commandManagement.deviceTypes': 'Device Types',
  'au.entity.commandManagement.devicePriority': 'Pri.',
  'au.entity.commandManagement.deviceType': 'Device Type',
  'au.entity.commandManagement.drag': 'Drag',
  'au.entity.commandManagement.remove': 'Remove',
  'au.entity.commandManagement.ftcpMessageName': 'FTCP Message Name',
  'au.entity.commandManagement.addConversionRule': 'Add Conversion Rule',
  'au.entity.commandManagement.saveChanges': 'Save Changes',
  'au.entity.commandManagement.updateJson': 'Update Json',
  'au.entity.commandManagement.validation.success': '<b>Valid - </b>It is properly configured.',
  'au.entity.commandManagement.validation.failed': '<b>Invalid - </b>It is not properly configured.',
  'au.entity.commandManagement.validationError': 'Validation Error',
  'au.entity.commandManagement.saveWarning': 'There is a validation error in your configuration. Would you like to save the draft without fixing it?',
  'au.entity.commandManagement.proceed': 'Yes, Proceed',
  'au.entity.commandManagement.viewValidation': 'View Validation',
  'au.entity.commandManagement.exitWithoutSaving': 'Exit without Saving',
  'au.entity.commandManagement.errorWarning': 'You are about to leave the page without saving changes you made to the command definition. Would you like to proceed?',
  'au.entity.commandManagement.view.fsmName': 'FSM Name',
  'au.entity.commandManagement.view.commandDefinitionStatus': 'Command Definition Status',
  'au.entity.commandManagement.view.commandType': 'Command Type',
  'au.entity.commandManagement.view.validityStatus': 'Validity Status',
  'au.entity.commandManagement.view.tags': 'Tags',
  'au.entity.commandManagement.publishConfirm.title': 'Publish Custom Command',
  'au.entity.commandManagement.publishConfirm.message1': 'You are about to publish a custom command definition',
  'au.entity.commandManagement.publishConfirm.message2': 'from Draft to Enabled. This action cannot be undone.',
  'au.entity.commandManagement.publishConfirm.message3': 'Would you like to proceed?',
  'au.entity.commandManagement.publishConfirm.proceed': 'Yes, Publish',
  'au.entity.commandManagement.tagEditor.placeholder.tagName': 'Key',
  'au.entity.commandManagement.tagEditor.placeholder.tagValue': 'Value',
  'au.entity.commandManagement.changeStatus.title': 'Change Status',
  'au.entity.commandManagement.changeStatus.message1': 'Would you like to change the status of this custom command?',
  'au.entity.commandManagement.changeStatus.currentStatus': 'Current Status:',
  'au.entity.commandManagement.changeStatus.changeTo': 'Change to:',
  'au.entity.commandManagement.prototypeError': 'The prototype does not exist with the version selected.',
  'au.entity.eventHistory.time': 'Time',
  'au.entity.eventHistory.logId': 'Log Id',
  'au.entity.eventHistory.type': 'Type',
  'au.entity.eventHistory.resourceName': 'Resource Name',
  'au.entity.eventHistory.resourceType': 'Resource Type',
  'au.entity.eventHistory.actor': 'Actor',
  'au.entity.eventHistory.aui': 'Resource Aui',
  'au.entity.eventHistory.updateDetails': 'Update Details',
  'au.entity.flowTable.status': 'Status',
  'au.entity.flowTable.name': 'Name or AUI',
  'au.entity.flowTable.type': 'Type',
  /* NOTE: Entity Names --------- */
  'au.entity.name.account': 'Account',
  'au.entity.name.announcement': 'Announcement',
  'au.entity.name.audit': 'Audit Log',
  'au.entity.name.artifact': 'Release Artifact',
  'au.entity.name.binding': 'Binding',
  'au.entity.name.bindingEvent': 'Binding Event',
  'au.entity.name.bundle': 'Target Bundle',
  'au.entity.name.client': 'Client',
  'au.entity.name.collection': 'Collection',
  'au.entity.name.command': 'Command',
  'au.entity.name.commandManagement': 'Custom Command',
  'au.entity.name.commandSummary': 'Command Summary',
  'au.entity.name.deployment': 'Deployment',
  'au.entity.name.device': 'Device',
  'au.entity.name.deviceState': 'Device State',
  'au.entity.name.fleet': 'Fleet',
  'au.entity.name.feed': 'Feed',
  'au.entity.name.flow': 'Flow',
  'au.entity.name.flowPermissions': 'Permission',
  'au.entity.name.geofence': 'Geofence',
  'au.entity.name.group': 'Group',
  'au.entity.name.incident': 'Incident',
  'au.entity.name.instance': 'Instance',
  'au.entity.name.journey': 'Journey',
  'au.entity.name.module': 'Module',
  'au.entity.name.outlet': 'Outlet',
  'au.entity.name.v1permission': 'Permission',
  'au.entity.name.policy': 'Policy',
  'au.entity.name.statement': 'Statement',
  'au.entity.name.stateTransitions': 'State Transition',
  'au.entity.name.processor': 'Processor',
  'au.entity.name.release': 'Release',
  'au.entity.name.ride': 'Ride',
  'au.entity.name.role': 'Role',
  'au.entity.name.tap': 'Tap',
  'au.entity.name.user': 'User',
  'au.entity.name.userGroup': 'User Group',
  'au.entity.name.userGroup.plural': 'user groups',
  'au.entity.name.userGroupsMember': 'User',
  'au.entity.name.vehicle': 'Vehicle',
  'au.entity.name.member': 'Member',
  'au.entity.name.metadata': 'Metadata',
  'au.entity.name.rawMessage': 'Metadata',
  'au.entity.name.fork': 'Fork',
  'au.entity.name.output': 'Output',
  'au.entity.name.fork_output': 'Fork Output',
  'au.entity.name.connectivityHistory': 'Connectivity History',
  'au.entity.name.smsHistory': 'SMS History',
  'au.entity.name.credentials': 'Credential',
  'au.entity.name.log': 'Log',
  'au.entity.name.updateLog': 'Log',
  /* NOTE: Entity Titles --------- */
  'au.entity.title.account': 'Accounts',
  'au.entity.title.actions': 'Actions',
  'au.entity.title.announcement': 'Announcements',
  'au.entity.title.artifact': 'Release Artifacts',
  'au.entity.title.audit': "Audit Logs",
  'au.entity.title.binding': 'Bindings',
  'au.entity.title.bindingEvent': 'Binding History',
  'au.entity.title.bundle': 'Target Bundles',
  'au.entity.title.client': 'Clients',
  'au.entity.title.collection': 'Collections',
  'au.entity.title.command': 'Commands',
  'au.entity.title.deviceWakeUp': 'Device Wake Up',
  'au.entity.title.commandSummary': 'Commands',
  'au.entity.title.commandManagement': 'Custom Commands',
  'au.entity.title.connectivityHistory': 'Connectivity History',
  'au.entity.title.deployment': 'Deployments',
  'au.entity.title.device': 'Devices',
  'au.entity.title.device.view': 'Details',
  'au.entity.title.vehicle.view': 'Details',
  'au.entity.title.deviceState': 'Device States',
  'au.entity.title.fleet': 'Fleets',
  'au.entity.title.feed': 'Feed',
  'au.entity.title.flow': 'Flows',
  'au.entity.title.flowPermissions': 'v1 Permissions',
  'au.entity.title.geofence': 'Geofences',
  'au.entity.title.group': 'Groups',
  'au.entity.title.incident': 'Incidents',
  'au.entity.title.instance': 'Instances',
  'au.entity.title.journey': 'Journeys',
  'au.entity.title.module': 'Modules',
  'au.entity.title.outlet': 'Outlets',
  'au.entity.title.v1permission': 'v1 Permissions',
  'au.entity.title.policy': 'Policies',
  'au.entity.title.statement': 'Policy Statements',
  'au.entity.title.userGroup': 'User Groups',
  'au.entity.title.userGroupsMember': 'Users',
  'au.entity.title.stateTransitions': 'State Transitions',
  'au.entity.title.processor': 'Processors',
  'au.entity.title.release': 'Releases',
  'au.entity.title.ride': 'Rides',
  'au.entity.title.role': 'Roles',
  'au.entity.title.rules': 'Rules',
  'au.entity.title.tap': 'Taps',
  'au.entity.title.user': 'Users',
  'au.entity.title.vehicle': 'Vehicles',
  'au.entity.title.member': 'Members',
  'au.entity.title.metadata': 'Metadata',
  'au.entity.title.rawMessage': 'Metadata',
  'au.entity.title.fork': 'Forks',
  'au.entity.title.smsHistory': 'SMS History',
  'au.entity.title.credential': 'Credential',
  'au.entity.title.validation': 'Validation',
  'au.entity.title.log': 'Update Logs',
  'au.entity.title.updateLog': 'Update Logs',
  'au.entity.title.archivedRule': 'Archived Rules',
  'au.entity.title.updateHistory': 'Update History',
  'au.entity.title.fields': 'fields',
  'au.entity.title.permittedFields': 'Permitted Fields',
  /* NOTE Entity Attributes --------- */
  'au.entity.attr.id': 'Id',
  'au.entity.attr.aui': 'Aui',
  'au.entity.attr.audit': 'Audit',
  'au.entity.attr.policies': 'Policies',
  'au.entity.attr.name': 'Name',
  'au.entity.attr.fullname': 'Full Name',
  'au.entity.attr.permissionId': 'Permission ID',
  'au.entity.attr.displayName': 'Display Name',
  'au.entity.attr.type': 'Type',
  'au.entity.attr.imei': 'Imei',
  'au.entity.attr.serial': 'Serial',
  'au.entity.attr.esn_serial': 'ESN / Serial',
  'au.entity.attr.make': 'Make',
  'au.entity.attr.messageName': 'Message Name',
  'au.entity.attr.messageType': 'Message Type',
  'au.entity.attr.fullName': 'Full Name',
  'au.entity.attr.model': 'Model',
  'au.entity.attr.year': 'Year',
  'au.entity.attr.vin': 'VIN',
  'au.entity.attr.tap': 'tap',
  'au.entity.attr.flow': 'flow',
  'au.entity.attr.businessUnit': 'Business Unit',
  'au.entity.attr.region': 'Region',
  'au.entity.attr.location': 'Location',
  'au.entity.attr.default': 'Default',
  'au.entity.attr.vehicle_id': 'Vehicle Id',
  'au.entity.attr.version': 'Version',
  'au.entity.attr.created': 'Created',
  'au.entity.attr.modified': 'Modified',
  'au.entity.attr.fleet_id': 'Fleet Id',
  'au.entity.attr.timezone': 'Timezone',
  'au.entity.attr.time': 'Time',
  'au.entity.attr.connectionStatus': 'Connection Status',
  'au.entity.attr.endpointName': 'Endpoint Name',
  'au.entity.attr.filter': 'Filter',
  'au.entity.attr.etag': 'eTag',
  'au.entity.attr.geoShape': 'Geo Shape',
  'au.entity.attr.vehicle': 'Vehicle',
  'au.entity.attr.waypoints': 'Waypoints',
  'au.entity.attr.rides': 'Rides',
  'au.entity.attr.device': 'Device',
  'au.entity.attr.devices': 'Devices',
  'au.entity.attr.previousStatus': 'Previous Status',
  'au.entity.attr.property': 'Property',
  'au.entity.attr.properties': 'Properties',
  'au.entity.attr.propertiesEdit': 'Properties',
  'au.entity.attr.reportedProperty': 'Reported Property',
  'au.entity.attr.tag': 'Tag',
  'au.entity.attr.tags': 'Tags',
  'au.entity.attr.description': 'Description',
  'au.entity.attr.oemMessageId': 'Message Id',
  'au.entity.attr.optional': '(Optional)',
  'au.entity.attr.resources': 'Resources',
  'au.entity.attr.startTime': 'Start Time',
  'au.entity.attr.endTime': 'End Time',
  'au.entity.attr.createTime': 'Create Time',
  'au.entity.attr.creatorAui': 'Creator Aui',
  'au.entity.attr.creator': 'Creator',
  'au.entity.attr.updateTime': 'Update Time',
  'au.entity.attr.expireTime': 'Expire Time',
  'au.entity.attr.assignmentCount': 'Assignment Count',
  'au.entity.attr.availableResources': 'Available Resources',
  'au.entity.attr.email': 'Email',
  'au.entity.attr.accountId': 'Account Id',
  'au.entity.attr.clientId': 'Client Id',
  'au.entity.attr.client': 'Client',
  'au.entity.attr.clientName': 'Client Name',
  'au.entity.attr.clientUsername': 'Client Username',
  'au.entity.attr.groupId': 'Group Id',
  'au.entity.attr.entitlement': 'Entitlement',
  'au.entity.attr.resource': 'Resource',
  'au.entity.attr.subject': 'Subject',
  'au.entity.attr.subjectAui': 'Subject Aui',
  'au.entity.attr.subjectClient': 'Subject - Client',
  'au.entity.attr.subjectUser': 'Subject - User',
  'au.entity.attr.subjectUserGroup': 'Subject - User Group',
  'au.entity.attr.object': 'Object',
  'au.entity.attr.objectAui': 'Object Aui',
  'au.entity.attr.role': 'Role',
  'au.entity.attr.roleAui': 'Role Aui',
  'au.entity.attr.flowAui': 'Flow Aui',
  'au.entity.attr.outputWithPosition': 'Output {position}',
  'au.entity.attr.inputFlow': 'Input Flow',
  'au.entity.attr.outputFlow': 'Output Flow',
  'au.entity.attr.filters': 'Filters',
  'au.entity.attr.flows.hint': 'No matching record found, so a new flow will be created.',
  'au.entity.attr.override.hint': 'Duplicate output flows must have the <b>same</b> buisness unit and region. The overwrite will be automatically applied to all duplicated output flows.',
  'au.entity.attr.inputForks': 'Input Forks',
  'au.entity.attr.outputForks': 'Output Forks',
  'au.entity.attr.inputTaps': 'Input Taps',
  'au.entity.attr.outputTaps': 'Output Taps',
  'au.entity.attr.shards': 'Shards',
  'au.entity.attr.shardCount': 'Shard Count',
  'au.entity.attr.status': 'Status',
  'au.entity.attr.reportedProperties': 'Reported Properties',
  'au.entity.attr.failureMode': 'Failure Mode',
  'au.entity.attr.bindings': 'Binding Devices',
  'au.entity.attr.bindingVin': 'Binding VIN',
  'au.entity.attr.username': 'Username',
  'au.entity.attr.updater': 'Updater',
  'au.entity.attr.asset': 'Asset',
  'au.entity.attr.statement': 'Statement',
  'au.entity.attr.statements': 'Statements',
  'au.entity.attr.resourceAui': 'Resource Aui',
  'au.entity.attr.entitlements': 'Entitlements',
  'au.entity.attr.enabled': 'Enabled',
  'au.entity.attr.effect': 'Effect',
  'au.entity.attr.event': 'Event',
  'au.entity.attr.deviceId': 'Device Id',
  'au.entity.attr.vehicleId': 'Vehicle',
  'au.entity.attr.userId': 'User Id',
  'au.entity.attr.deviceType': 'Device Type',
  'au.entity.attr.currentlyBound': 'Currently Bound',
  'au.entity.attr.correlationId': 'Correlation Id',
  'au.entity.attr.failIfPrecluded': 'Fail If Precluded',
  'au.entity.attr.wakeUp': 'Wake Up',
  'au.entity.attr.wakeupEvaluation': 'Wake Up Evaluation',
  'au.entity.attr.deviceWakeupState': 'Wake Up State',
  'au.entity.attr.statusReason': 'Status Reason',
  'au.entity.attr.author': 'Author',
  'au.entity.attr.expiration': 'Expiration',
  'au.entity.attr.artifactId': 'Artifact Id',
  'au.entity.attr.artifactIds': 'Artifacts',
  'au.entity.attr.release': 'Release',
  'au.entity.attr.releaseId': 'Release Id',
  'au.entity.attr.bundleId': 'Bundle Id',
  'au.entity.attr.bundleIds': 'Bundles',
  'au.entity.attr.targetBundles': 'Target Bundles',
  'au.entity.attr.deployCount': 'Deploy Count',
  'au.entity.attr.failureCount': 'Failure Count',
  'au.entity.attr.successCount': 'Success Count',
  'au.entity.attr.deploymentId': 'Deployment Id',
  'au.entity.attr.deploymentState': 'Deployment State',
  'au.entity.attr.matchers': 'Matchers',
  'au.entity.attr.uri': 'Uri',
  'au.entity.attr.state': 'State',
  'au.entity.attr.value': 'Value',
  'au.entity.attr.denyAttributeTags': 'Deny Attribute Tags',
  'au.entity.attr.action': 'Action',
  'au.entity.attr.actor': 'Actor',
  'au.entity.attr.eventType': 'Type',
  'au.entity.attr.resourceAttributes': 'Resource Attributes',
  'au.entity.attr.resourceTags': 'Resource Tags',
  'au.entity.attr.resourceType': 'Resource Type',
  'au.entity.attr.policy': 'Policy',
  'au.entity.attr.permission': 'Permission',
  'au.entity.attr.targetEntitlement': 'Target Entitlement',
  'au.entity.attr.condition': 'Condition',
  'au.entity.attr.timestamp': 'Time',
  'au.entity.attr.noop': ' ',
  'au.entity.attr.inputProcessors': 'Input Processors',
  'au.entity.attr.outputProcessors': 'Output Processors',
  'au.entity.attr.output': 'Output',
  'au.entity.attr.input': 'Input',
  'au.entity.attr.address': 'Address',
  'au.entity.attr.dids': 'DIDs',
  'au.entity.attr.metrics': 'Metrics',
  'au.entity.attr.oemData': 'OEM Data',
  'au.entity.attr.error': 'Error',
  'au.entity.attr.ftcpMessageName': 'FTCP Type',
  'au.entity.attr.ftcpVersion': 'FTCP Version',
  'au.entity.attr.ftcpCorrelationId': 'FTCP Correlation Id',
  'au.entity.attr.ftcpMessageId': 'FTCP Message Id',
  'au.entity.attr.esn': 'ESN',
  'au.entity.attr.shardId': 'Shard Id',
  'au.entity.attr.refId': 'Ref Id',
  'au.entity.attr.ftcpMessages': 'FTCP Messages',
  'au.entity.attr.typePlaceholder': 'Type',
  'au.entity.attr.choosePlaceholder': 'Choose',
  'au.entity.attr.commandResponse': 'Command Response',
  'au.entity.attr.correlatedAlert': 'Correlated Alert',
  'au.entity.attr.fromState': 'From State',
  'au.entity.attr.toState': 'To State',
  'au.entity.attr.message': 'Message',
  'au.entity.attr.outputFlows': 'Output Flows',
  'au.entity.attr.outputPos': 'Output {position}',
  'au.entity.attr.entity': 'Entity',
  'au.entity.attr.filterDetails': 'Filter Details',
  'au.entity.attr.outputs': 'Outputs',
  'au.entity.attr.addOutput': '+ Add Output',
  'au.entity.attr.outputsView': 'Output {position}',
  'au.entity.attr.sourceId': 'Source Id',
  'au.entity.attr.groupName': 'Group Name',
  'au.entity.attr.vehicleModel': 'Vehicle Model',
  'au.entity.attr.vehicleModelYear': 'Vehicle Model Year',
  'au.entity.attr.outputId': 'Output Id',
  'au.entity.attr.flowName': 'Flow Name',
  'au.entity.attr.ftcpCloudToDeviceMessage': 'FTCP Cloud To Device Message',
  'au.entity.attr.ftcpDeviceToCloudMessage': 'FTCP Device To Cloud Message',
  'au.entity.attr.fsm': 'FSM',
  'au.entity.attr.fsm.commandWithResponse': 'command with response',
  'au.entity.attr.fsm.commandWithoutResponse': 'command without response',
  'au.entity.attr.fsm.commandWithUserInput': 'command with user input',
  'au.entity.attr.ftcpVersions': 'FTCP Versions',
  'au.entity.attr.commandType': 'Command Type',
  'au.entity.attr.customCommandVersion': 'Custom Command Version',
  'au.entity.attr.parentDefinitionId': 'Parent Definition Id',
  'au.entity.attr.targetDevices': 'Target Devices',
  'au.entity.attr.permissions': 'Permissions',
  'au.entity.attr.fsmName': 'fsmName',
  'au.entity.attr.fsmName.COMMAND_WITH_RESPONSE': 'Command with response',
  'au.entity.attr.fsmName.COMMAND_WITHOUT_RESPONSE': 'Command without response',
  'au.entity.attr.fsmName.COMMAND_WITH_USER_INPUT': 'Command with user input',
  'au.entity.attr.commandDefinitionStatus': 'Command Definition Status',
  'au.entity.attr.maxExpirationSeconds': 'Max Expiration Seconds',
  'au.entity.attr.maxExpirationInSeconds': 'Max Expiration (seconds)',
  'au.entity.attr.customCommandVersions': 'Custom Command Versions',
  'au.entity.attr.wakeUpTable': 'Wake Up',
  'au.entity.attr.parameter': 'Parameter',
  'au.entity.attr.connectivitySource': 'Connectivity Source',
  'au.entity.attr.disconnectReason': 'Disconnect Reason',
  'au.entity.attr.deliveryStatus': 'Delivery Status',
  'au.entity.attr.smsId': 'SMS Id',
  'au.entity.attr.carrier': 'Carrier',
  'au.entity.attr.wakeupState': 'Wakeup State',
  'au.entity.attr.carrierDelivery': 'Carrier Delivery Time',
  'au.entity.attr.deviceDelivery': 'Device Delivery Time',
  'au.entity.attr.timeValue': '{time}',
  'au.entity.attr.smsIdValue': 'SMS Id: {id}',
  'au.entity.attr.valid': 'Validity',
  'au.entity.attr.tokenIssuedTime': 'Token Issue Time',
  'au.entity.attr.publishingDestinations': 'Publishing Destinations',
  'au.entity.attr.ruleId': 'Rule Id',
  'au.entity.attr.validFrom': 'Valid From',
  'au.entity.attr.ruleName': 'Rule Name',
  'au.entity.attr.precedenceFlag': 'Precedence Flag',
  'au.entity.attr.validTime': 'Valid Time',
  'au.entity.attr.fieldId': 'Field Id',
  'au.entity.attr.pathToField': 'Path To Field',
  /* NOTE: Entity Actions --------- */
  'au.entity.action.edit': 'Edit {entityName}',
  'au.entity.action.create': 'Create {entityName}',
  'au.entity.action.delete': 'Delete {entityName}',
  'au.entity.action.search': 'Search {entityName}',
  'au.entity.action.update': 'Update {entityName}',
  'au.entity.action.manage': 'Manage {entityName}',
  'au.entity.action.register': 'Register {entityName}',
  'au.entity.action.replicate': 'Replicate {entityName}',
  'au.entity.action.bind': 'Bind {entityName}',
  'au.entity.action.remove': 'Remove {entityName}',
  'au.entity.delete.body': 'Are you sure you want to delete this {entityName}?',
  'au.entity.remove.body': 'Are you sure you want to remove this {entityName} from the group?',
  /* NOTE: Entity misc */
  'au.entity.type.placeholder': 'Type {attrName}',
  'au.entity.status.title': '{title}',
  'au.entity.typeOrPaste.placeholder': 'Type or paste {attrName}',
  'au.entity.lookup.placeholder': 'Lookup {fieldName}',
  'au.entity.noEntityFound': 'No {entityName} found with {attrName} matching "{attrValue}"',
  'au.entity.feedEventLink': 'Feed Event Rates',
  'au.entity.noRecordFound.notice.create': 'No matching record found. A new {entityName} will be created.',
  'au.entity.fieldSelector.availableFields': 'Available Fields',
  /* NOTE End Entity section --------- */
  'au.accounts.account': 'Account',
  'au.accounts.user.id': 'Id',
  'au.accounts.user.username': 'Username',
  'au.accounts.user.email': 'Email',
  'au.accounts.change': 'Change',
  'au.accounts.title.selectAccount': 'Select Account',
  'au.accounts.title.changeAccount': 'Change Account',
  'au.accounts.changeAccountTo': 'Change account to',
  'au.accounts.currentAccount': 'Current Account',
  'au.accounts.saveChange': 'Save Change',
  'au.accounts.cancel': 'Cancel',
  'au.cancel': 'Cancel',
  'au.userProfile.title.loggedUser': 'Logged In User',
  'au.userProfile.title.entityStrucure': 'Entity Structure',
  'au.userProfile.title.loginHistory': 'Login History',
  'au.userProfile.fullName': 'Name',
  'au.userProfile.userType': 'User Type',
  'au.userProfile.emailAddress': 'Email',
  'au.userProfile.changeView': 'Change View',
  'au.userProfile.currentView': 'Current View',
  'au.userProfile.tags': 'Tags',
  'au.userProfile.addTag': 'Add Tag',
  'au.permitsLookup.application.hint': "To lookup permits by the rules, please add rules to the table below by choosing the rule ID or typing the rule name.",
  'au.permitsLookup.tab.properties': 'By Properties',
  'au.permitsLookup.tab.application': 'By Application Rules',
  'au.permitsLookup.lookupPermits': 'Lookup Permits',
  'au.permitsLookup.lookupPermitsForMessage': 'Lookup Permits for Message',
  'au.permitsLookup.topLevelMessageName': 'Top Level Message Name',
  'au.permitsLookup.namespaceFtcpVersion': 'Namespace (FTCP Version)',
  'au.permitsLookup.placeholder.typeOrChoose': 'Type or choose',
  'au.permitsLookup.placeholder.choose': 'Choose',
  'au.permitsLookup.placeholder.chooseRuleId': 'Choose rule Id',
  'au.permitsLookup.placeholder.typeRuleName': 'Type rule name',
  'au.permitsLookup.add': 'Add',
  'au.permitsLookup.groupId': 'Group Id',
  'au.permitsLookup.groupName': 'Group Name',
  'au.permitsLookup.ruleId': 'Rule Id',
  'au.permitsLookup.ruleName': 'Rule Name',
  'au.permitsLookup.remove': 'Remove',
  'au.permitsLookup.nothingToDisplay': 'Nothing to display',
  'au.permitsLookup.typeTagKey': 'Type tag key',
  'au.permitsLookup.typeTagValue': 'Type tag value',
  'au.permitsLookup.chooseId': 'Choose Id',
  'au.permitsLookup.typeIdOrName': 'Type Id or name',
  'au.permitsLookup.chooseBoolean': 'Choose Boolean',
  'au.permitsLookup.tags': 'Tags',
  'au.permitsLookup.groups': 'Groups',
  'au.permitsLookup.groupIds': 'Groups',
  'au.permitsLookup.tags.hint': '* Please use space, comma, or semicolon to separate values. Max 24 characters for tag key and 40 characters for tag value.',
  'au.permitsLookup.consentsByPOU': 'Consent by POUs',
  'au.permitsLookup.pou.hint': '* Please use space, comma, or semicolon to separate values.',
  'au.permitsLookup.count': '{count, plural, one {1 rule} other {# rules}} selected',
  'au.permitsLookup.invalidPou': 'Invalid format. POU contains only numbers',
  'au.permitsLookup.Pou': 'POU',
  'au.permitsLookup.boolean': 'Boolean',
  'au.permitsLookup.optional': '(Optional)',
  'au.permitsLookup.namespace': 'Namespace (FTCP Version)',
  'au.permitsLookup.permitsForMessage': 'Permits for Message',
  'au.permitsLookup.applicableRuleIds': 'Rules',
  'au.permitsLookup.chooseMessage': 'Choose a message to lookup permits for the rule.',
  'au.tagEditor.tags': 'Tags',
  'au.tagEditor.tagName': 'Tag Name',
  'au.tagEditor.tagValue': 'Tag Value',
  'au.tagEditor.clearAll': 'Clear All',
  'au.tagEditor.placeholder.tagName': 'Type tag name',
  'au.tagEditor.placeholder.tagValue': 'Type tag value',
  'au.vinEditor.placeholder.typeVins': 'Type or paste VINs',
  'au.vinEditor.vins': 'VINs',
  'au.vinEditor.clearAll': 'Clear All',
  'au.vinEditor.vinsSeparationNote': 'Please use space, comma or semicolon to separate VINs',
  'au.vinEditor.invalidVins': '{count, plural, one {One VIN number is} other {# VIN numbers are}} not valid',
  'au.vinEditor.maxVins': '{count, plural, one {One VIN number is} other {# VIN numbers}} entered. Limit <cta>100</cta> VINS per VIN filter.',
  'au.groupMembersEditor.vehicle.placeholderId': 'Type or paste IDs/VINs',
  'au.groupMembersEditor.vehicle.separationNote': 'Please use space, comma or semicolon to separate IDs/VINs',
  'au.groupMembersEditor.device.placeholderId': 'Type or paste IDs',
  'au.groupMembersEditor.device.separationNote': 'Please use space, comma or semicolon to separate IDs',
  'au.groupMembersEditor.input.device.placeholderId': 'Device Id',
  'au.groupMembersEditor.input.vehicle.placeholderId': 'Vehicle Id or VIN',
  'au.taps.tapId': 'Tap Id',
  'au.taps.pause': 'Pause',
  'au.taps.pauseConfirm': 'Pause',
  'au.taps.latest': 'Latest',
  'au.taps.default': '(Default)',
  'au.taps.note': 'Note',
  'au.taps.noteMessage': 'Tap will unpause automatically after one hour in order to prevent lag from building too high.',
  'au.taps.pointOfPause': 'Point of pause',
  'au.taps.message': 'Please choose the data resume point. When the tap is unpaused, its data stream will pick up at an offset you specified.',
  'au.taps.start': 'Start Tap',
  'au.taps.sequence': 'Sequence',
  'au.taps.seq': 'Seq.',
  'au.taps.filterType': 'Filter Type',
  'au.taps.tablet.filterType': 'Filters',
  'au.taps.details': 'Details',
  'au.taps.type': 'Permit/Deny',
  'au.taps.permit': 'Permit',
  'au.taps.deny': 'Deny',
  'au.taps.order': 'Order',
  'au.taps.delete': 'Delete',
  'au.taps.actions': 'Actions',
  'au.taps.addNewFilter': 'Add New Filter',
  'au.taps.vins': 'VINs',
  'au.taps.role': 'Role',
  'au.taps.client': 'Client',
  'au.taps.attributeKey': 'Key',
  'au.taps.attributeUri': 'uri',
  'au.taps.attributeName': 'Name',
  'au.taps.attributeValue': 'Value',
  'au.taps.deviceType': 'Type',
  'au.taps.id': 'Id',
  'au.taps.name': 'Name',
  'au.taps.clearFilters': 'Clear',
  'au.taps.saveFilters': 'Save Filters',
  'au.taps.saveFilters.wait': 'Saving...',
  'au.taps.tagKey': 'Key',
  'au.taps.tagValue': 'Value',
  'au.taps.typePermit': 'Permit',
  'au.taps.typeDeny': 'Deny',
  'au.taps.manageFilters': 'Manage Filters',
  'au.taps.displayName': 'Display Name',
  'au.taps.valid': 'Valid',
  'au.taps.invalid': 'Not Valid',
  'au.taps.incomplete': 'Not Complete',
  'au.taps.configurationNotNecessary': 'Configuration is not necessary.',
  'au.taps.invalidDetails': "It requires a permitted membership filter (IAM, Device, Group, or VIN) with a permitted attribute filter.",
  'au.taps.validation.success': 'It is properly configured.',
  'au.taps.validation.warning': '<b>Not Complete</b> - Please note that this tap isn’t properly configured. It require following filters to emit telemetry data:',
  'au.taps.validation.info': 'To create a tap, each of these filters must be added to the output flow.',
  'au.taps.validation.filterDetails': 'Filter Details',
  'au.taps.validation.entityDetails': '{entityName} Details',
  'au.taps.filters.memberFilters': 'Member Filters',
  'au.taps.filters.signalFilters': 'Signal Filters',
  'au.taps.filters.member': 'One Member Filter',
  'au.taps.filters.signal': 'One Signal Filter',
  'au.taps.filters.permit': 'At least one permitted filter type',
  'au.taps.filters.wildCard.valid': 'Valid wildcard format',
  'au.taps.filters.wildCard.invalid': 'Invalid wildcard format. It should contain a preceding period (.*)',
  'au.taps.filters.leaveMessage': 'If you leave this page without saving, your changes will be lost.',
  'au.taps.filters.clear.title': 'Clear Filters',
  'au.taps.filters.clear.msg': 'All the changes will be lost. Are you sure you want to proceed?',
  'au.taps.filters.clear.proceed': 'Yes, proceed',
  'au.taps.filters.addVins': 'Add VINs',
  'au.taps.filters.deleteVins': 'Delete VINs',
  'au.taps.filters.deleting': 'Deleting...',
  'au.taps.filters.vinEditor.cancel': 'Cancel',
  'au.taps.filters.vinEditor.adding': 'Adding...',
  'au.taps.filters.vinEditor.deleting': 'Deleting...',
  'au.taps.filters.PERMIT': 'Permit',
  'au.taps.filters.DENY': 'Deny',
  'au.taps.filter.save': 'Save',
  'au.taps.filter.saving': 'Saving...',
  'au.taps.filter.cancel': 'Cancel',
  'au.taps.filter.clear': 'Clear',
  'au.taps.filter.moveFilter': 'Change Filter Sequence',
  'au.taps.filter.deleteFilter': 'Delete Filter',
  'au.taps.filter.addFilterAbove': 'Add Filter Above',
  'au.taps.filter.addFilterBelow': 'Add Filter Below',
  'au.taps.filter.permit': 'Permit',
  'au.taps.filter.deny': 'Deny',
  'au.taps.filter.types.PERMIT': 'Permit',
  'au.taps.filter.types.DENY': 'Deny',
  'au.taps.filter.details.name': 'Name',
  'au.taps.filter.details.value': 'Value',
  'au.taps.filter.details.vins': 'VINs',
  'au.taps.filter.details.subject': 'Subject',
  'au.taps.filter.details.role': 'Role',
  'au.taps.filter.details.groupId': 'Id',
  'au.taps.filter.details.uri': 'uri',
  'au.taps.filter.details.sampleRate': 'sample_rate',
  'au.taps.filter.details.deviceType': 'Device Type',
  'au.taps.filter.details.tagKey': 'Tag Key',
  'au.taps.filter.details.tagValue': 'Tag Value',
  'au.taps.filters.attributeFilter': 'Attribute Filter',
  'au.taps.filters.attributeTagFilter': 'Attribute Tag Filter',
  'au.taps.filters.attributeTagFilter.emptyCommand': '-- Empty Command Type --',
  'au.taps.filters.deviceFilter': 'Device Filter',
  'au.taps.filters.groupFilter': 'Group Filter',
  'au.taps.filters.iamFilter': 'IAM Filter',
  'au.taps.filters.membershipTagFilter': 'Membership Tag Filter',
  'au.taps.filters.tagFilter': 'Tag Filter',
  'au.taps.filters.vinFilter': 'VIN Filter',
  'au.taps.filters.memberPassThroughFilter': 'Member PassThrough Filter',
  'au.taps.filters.signalPassThroughFilter': 'Signal PassThrough Filter',
  'au.taps.filters.type': 'Type',
  'au.taps.filters.filterNumber': 'Filter {number}',
  'au.taps.filters.filterDetail': 'Filter Detail',
  'au.taps.filters.groupId': 'Id: ',
  'au.taps.filters.groupName': 'Name: ',
  'au.taps.filters.createNewGroup': 'To create a new group, <a>click here</a>',
  'au.taps.filters.createNewGroup.hint': 'Type in a group name to create, or <a>enter Id</a>',
  'au.taps.replicationNotice': 'Please note that VINs can not be replicated between partitions.',
  'au.incidents.incidentTitle': 'Incident',
  'au.incidents.affectedServices': 'Affected Services',
  'au.incidents.lastModified': 'Last Modified',
  'au.incidents': '{count, plural, one {One incident is} other {# incidents are}} reported.',
  'au.shardCount.12': '12',
  'au.shardCount.24': '24',
  'au.shardCount.48': '48',
  'au.shardCount.96': '96',
  'au.shardCount.hint': "* The shard count cannot be changed later. If you are not using the recommended shard count, it is highly recommended that you consult with the TMC Support team.",
  'au.status.title.currentStatus': 'Current Status',
  'au.status.success': 'All TMC services are operational.',
  'au.status.warning': '{servicesDegraded, plural, one {One service is} other {# services are}} degraded.',
  'au.status.alert': '{servicesDown, plural, one {One service is} other {# services are}} down{servicesDegraded, plural, one { and one service is degraded} other { and # services are degraded}}.',
  'au.services.ACCOUNTS_SERVICE': 'Accounts Service',
  'au.services.BEHAVIOR_QUERY_SERVICE': 'Behavior Query Service',
  'au.services.BOOTSTRAP_SERVICE': 'Bootstrap Service',
  'au.services.BULK_EXTRACT_SERVICE': 'Bulk Extract Service',
  'au.services.BYTESTREAM_SERVICE': 'Bytestream Service',
  'au.services.CLOUD_INGEST_SERVICE': 'Cloud Ingest Service',
  'au.services.COMMAND_SERVICE': 'Command Service',
  'au.services.DEPLOYMENT_SERVICE': 'Deployment Service',
  'au.services.EDGE_SERVICE': 'Edge Service',
  'au.services.EDGE_FNV2_SERVICE': 'FNV2 MMOTA Edge Service',
  'au.services.FEED_SERVICE': 'Feed Service',
  'au.services.FEED_ADMIN_SERVICE': 'Feed Admin Service',
  'au.services.FUEL_VOLUME_SERVICE': 'Fuel Volume Service',
  'au.services.GEOFENCE_SERVICE': 'Geofence Service',
  'au.services.GROUP_SERVICE': 'Group Service',
  'au.services.GROUP_ADMIN_SERVICE': 'Group Admin Service',
  'au.services.HISTORY_SERVICE': 'History Service',
  'au.services.IAM_SERVICE': 'IAM Service',
  'au.services.INGESTION_SERVICE': 'Ingestion Service',
  'au.services.TMC_METRICS_SERVICE': 'TMC Metrics Service',
  'au.services.INVENTORY_SERVICE': 'Inventory Service',
  'au.services.METRICS_QUERY_SERVICE': 'Metrics Query Service',
  'au.services.OEM_COMMAND_SERVICE': 'OEM Command Service',
  'au.services.PROVISIONING_SERVICE': 'Provisioning Service',
  'au.services.RESOURCES_SERVICE': 'Resources Service',
  'au.services.RIDE_HAILING_SERVICE': 'Ride-Hailing Service',
  'au.services.TELEMETRY_SERVICE': 'Telemetry Service',
  'au.services.TMC_IOT_SERVICE': 'TMC IoT Service',
  'au.services.VEHICLE_STATE_STREAMING_SERVICE': 'Vehicle State Streaming Service',
  'au.services.PROCESSING_PIPELINES_SERVICE': 'Processing Pipelines Service',
  'au.services.KEY_ROTATION_SERVICE': 'Key Rotation Service',
  'au.services.REGISTRATION_SERVICE': 'Registration Service',
  'au.services.OTHER_SERVICES': 'Other Services',
  'au.auditActionSelector.placeholder.auditAction': 'Select an action',
  'au.client.copy': 'Copy',
  'au.client.copied': 'Copied',
  'au.client.clientId': 'Client Id',
  'au.client.displayName': 'Display Name',
  'au.client.resetSecret': 'Reset Secret',
  'au.client.clientSecret': 'Client Secret',
  'au.client.clientCreated': 'Client Created',
  'au.client.clientIdAndSecretEnclosed': 'Keep it somewhere safe but accessible. You will not be able to recover it after you leave this page, but you can generate a new secret at any time.',
  'au.client.secretGenerated': 'Client Secret Generated',
  'au.client.keepSecretSafe': 'Keep it somewhere safe but accessible. You will not be able to recover it after you leave this page.',
  'au.client.resetSecret.title': 'Are you sure?',
  'au.client.resetSecret.message1': 'Any applications that use your current secret will break until you update them with the new secret.',
  'au.client.resetSecret.message2': 'Are you sure you want to proceed?',
  'au.client.resetSecret.confirm': 'Yes, reset it',
  'au.client.credential.securityTip': '<b>Security Tip - </b>Rotate your secret annually to increase security and maintain your data integrity.',
  'au.client.startRotation': 'Start Rotation',
  'au.client.secretRotation': 'Secret Rotation',
  'au.client.credential': 'Credential',
  'au.client.credentialId': 'Credential Id',
  'au.client.credentialLatest': 'Credential (Latest)',
  'au.client.credential.rollback': 'Rollback',
  'au.client.credential.delete': 'Delete',
  'au.client.credential.deleteCredential': 'Delete Credential',
  'au.client.credential.delete.id': 'Credential Id:',
  'au.client.credential.deleteDialog': 'This action cannot be undone. Are you sure you want to delete the credential?',
  'au.client.credential.proceed': 'Proceed',
  'au.client.credential.rollbackDialog': 'This action cannot be undone. It will delete the newly generated secret. Are you sure you want to delete it?',
  'au.client.credential.success': 'Success',
  'au.client.credential.successDialog': 'The secret was deleted successfully and the client was rolled back to the previous credential.',
  'au.client.credential.successButton': 'Ok',
  'au.client.credential.tooltip': 'The credential can not be deleted when used within 3 days.',
  'au.client.rotation.step1': 'Step 1: Generate new secret',
  'au.client.rotation.step2': 'Step 2: Update application',
  'au.client.rotation.step3': 'Step 3: Delete the old credential and finish the secret rotation',
  'au.client.rotation.step1.info': 'Begin the secret rotation process by generating a new secret.',
  'au.client.rotation.step2.info': 'All applications that use your current secret will need to be updated with the new secret. Once you have updated your application, the token issue time for the new secret will begin populating in the console.',
  'au.client.rotation.step3.info': 'Both secrets will be valid for your client until you delete the old credential. To complete the rotation, delete the old credential so that only one credential remains.',
  'au.client.rotation.complete': 'COMPLETE',
  'au.client.rotation.generateSecret': 'Generate Secret',
  'au.commands.commandHistory': 'Command History',
  'au.commands.commandId': 'Command Id',
  'au.commands.currentStatus': 'Status',
  'au.commands.currentStatusValue': 'Status Value',
  'au.commands.vehicleId': 'Vehicle Id',
  'au.commands.vehicleVin': 'VIN',
  'au.commands.deviceId': 'Device Id',
  'au.commands.placeholder.type': 'Select a type',
  'au.commands.viewSmsDeliveryHistory': 'View SMS Delivery History',
  'au.customcommands.status.DRAFT': 'Draft',
  'au.customcommands.status.ENABLED': 'Enabled',
  'au.customcommands.status.DISABLED': 'Disabled',
  'au.commands.wakeUp': 'Request Issued',
  'au.commands.wakeupEvaluation': 'Evaluation',
  'au.commands.wakeupState': 'State',
  'au.eventBindings.eventTime': 'Event Time',
  'au.forks.forkId': 'Fork Id',
  'au.forks.overrideBusinessUnitorRegion': 'Overwrite the business unit or the region',
  'au.forks.businessUnitorRegionForm': 'To overwrite the business unit or the region, please fill out the form below. Otherwise, they would be inherited from the fork.',
  'au.forks.pauseFork': 'Pause Fork',
  'au.forks.validation.info': 'To create a fork, a filter must be added to the output flow.',
  'au.zoom.label': 'Zoom',
  'au.topology.usageTitle': 'Topology Icons usage',
  'au.topology.renderingPleaseWait': 'Rendering your data flow… Please wait…',
  'au.topology.label.search': 'Search',
  'au.topology.label.zoom': 'Zoom',
  'au.topology.label.legend': 'Legend',
  'au.topology.label.download': 'Download',
  'au.topology.label.recenter': 'Re-center',
  'au.topology.fragment.topologyView': 'Topology View',
  'au.topology.fragment.fullTopologyView': 'Full Topology View',
  'au.topology.fragment.halfTopologyView': 'Half Topology View',
  'au.topology.fragment.hideTopologyView': 'Hide Topology View',
  'au.topology.fragment.search': 'Search',
  'au.topologyLegend.nodes.rootFlow': 'Flow',
  'au.topologyLegend.nodes.connectedFlow': 'Flow',
  'au.topologyLegend.nodes.tap': 'Running Tap',
  'au.topologyLegend.nodes.inferredTap': 'Inferred Tap',
  'au.topologyLegend.nodes.pausedTap': 'Paused Tap',
  'au.topologyLegend.nodes.stoppedTap': 'Stopped Tap',
  'au.topologyLegend.nodes.flow': 'Flow',
  'au.topologyLegend.nodes.inferredFlow': 'Inferred Flow',
  'au.topologyLegend.nodes.processor': 'Running Processor',
  'au.topologyLegend.nodes.inferredProcessor': 'Inferred Processor',
  'au.topologyLegend.nodes.pausedProcessor': 'Paused Processor',
  'au.topologyLegend.nodes.stoppedProcessor': 'Stopped Processor',
  'au.topologyLegend.nodes.fork': 'Running Fork',
  'au.topologyLegend.nodes.pausedFork': 'Paused Fork',
  'au.topologyLegend.nodes.stoppedFork': 'Stopped Fork',
  'au.topologyLegend.nodes.inferredFork': 'Inferred Fork',
  'au.topologyLegend.nodes.directRoute': 'Direct Route',
  'au.topologyLegend.nodes.inferredDirectRoute': 'Inferred Direct Route',
  'au.topologySearch.placeholder': 'Type or paste Tap or Flow AUI',
  'au.group.id': 'Group Id',
  'au.group.displayName': 'Group Name',
  'au.group.addMembers': 'Add Members',
  'au.group.removeMembers': 'Remove Members',
  'au.group.members.vehiclesCount': '{count, plural, one {1 vehicle} other {# vehicles}}',
  'au.group.members.devicesCount': '{count, plural, one {1 device} other {# devices}}',
  'au.group.members.vehiclesAndDevicesCount': '{vehiclesMsg} and {devicesMsg}',
  'au.group.members.saveSuccess.add': '{members} {count, plural, one {was} other {were}} successfully added to the group',
  'au.group.members.saveSuccess.remove': '{members} {count, plural, one {was} other {were}} successfully removed from the group',
  'au.group.members.saveError.add': 'Adding Members Failed',
  'au.group.members.saveError.remove': 'Removing Members Failed',
  'au.group.members.saveErrorReasons': 'The following members had problems: {messages}',
  'au.group.members.properties': 'VIN / Serial',
  'au.group.members.vehicles': 'Vehicles',
  'au.group.members.devices': 'Devices',
  'au.policies.v1Permission': 'Permission',
  'au.policies.v1Permission.warning': 'This is a v1 Permission. It is not editable on Au Console.',
  'au.policies.manageStatements': 'Manage Statements',
  'au.policies.createTargetPolicy': 'Create Target Policy',
  'au.policies.jsonViewer': 'JSON Viewer',
  'au.policies.loadMore': 'Load more',
  'au.policies.statementsAdded': '{number} statements added!',
  'au.policies.subjectAui': 'Subject Aui',
  'au.policies.resourceAui': 'Resource Aui',
  'au.policies.description': 'Description',
  'au.policies.condition': 'Condition',
  'au.policies.entitlements': 'Entitlements',
  'au.policies.effect': 'Effect',
  'au.policies.effects.ALLOW': 'Allow',
  'au.policies.effects.DENY': 'Deny',
  'au.policies.creatorAui': 'Actor',
  'au.policies.author': 'Author',
  'au.policies.createTime': 'Created Time',
  'au.policies.groups.DIRECT': 'Target Policy',
  'au.policies.groups.OVERLAY': 'Overlay Policies',
  'au.policies.groups.MEMBERSHIP': 'Group Policies',
  'au.policies.noPoliciesYet': 'There are no policies yet',
  'au.policies.addStatement': '+ Add Statement',
  'au.policies.types.RESOURCE': 'RESOURCE_BASED_POLICY',
  'au.policies.types.SUBJECT': 'SUBJECT_BASED_POLICY',
  'au.policies.morePolicyStatementsAvailable': 'More {policyGroup} statements available. Want to see more?',
  'au.policies.cannotBeCreated': 'Policies cannot be created against Public Clients.  Policies can be created against a User through the {manageUsers} pages.',
  'au.policies.manageUsers': 'Manage Users',
  'au.policies.retentionMessage': 'TMC retains policy history for up to 1 year',
  'au.audit.appliedPolicy': 'Applied Policy',
  'au.audit.changedPolicyStatement': 'Changed Policy Statement {number}',
  'au.policies.history.appliedPolicyDetails': 'Applied Policy Details',
  'au.policies.history.title': 'History',
  'au.policies.history.changes': 'Changes',
  'au.policies.history.previous': 'Previous',
  'au.policies.history.update': 'Update',
  'au.policies.history.noItems': 'No history for this policy statement found',
  'au.statementEditor.optional': '(Optional)',
  'au.statementEditor.delete': 'Delete',
  'au.statementEditor.undo': 'Undo',
  'au.statementEditor.markedForDeletion': 'Marked for deletion',
  'au.entitlementEditor.entitlement': 'Entitlement',
  'au.entitlementEditor.optional': '(Opitonal)',
  'au.entitlementEditor.placeholder.entitlement': 'Choose from available entitlements',
  'au.entitlementEditor.placeholder.entitlements': 'Type or paste entitlements',
  'au.entitlementEditor.placeholder.subjectAui': 'Type or paste Subject Aui',
  'au.entitlementEditor.separationNote': 'Please use space, comma, or semicolon to separate entitlements',
  'au.entitlementEditor.invalidEntitlements': '{count, plural, one {One entitlement is} other {# entitlements are}} not valid',
  'au.entitlementEditor.editingExistingStatement': 'This is a duplicated statement. This action would overwrite the existing statement.',
  'au.permissions.obsoleteWarning': 'This page is showing IAM v1 permissions. You can still use this page to manage existing permissions or create new ones. However we highly encourage to use {v2Policies}.',
  'au.permissions.v2Policies': 'IAM v2 policies',
  'au.jsonViewer.jsonView': 'JSON View',
  'au.jsonViewer.copy': 'Copy',
  'au.jsonViewer.copied': 'Copied',
  'au.jsonViewer.genericError': 'Sorry, we cannot provide the JSON at this time. Please, try again later.',
  'au.processors.processorName': 'Processor Name',
  'au.processors.updateTime': 'Updated Time',
  'au.processors.processorId': 'Processor Id',
  'au.processors.type.locationCleansingProcessor': 'LocationCleansingProcessor',
  'au.processors.type.speedCleansingProcessor': 'SpeedCleansingProcessor',
  'au.processors.type.timezoneOffsetProcessor': 'TimezoneOffsetProcessor',
  'au.processors.type.tirePressureProcessor': 'TirePressureProcessor',
  'au.processors.type.tripIdProcessor': 'TripIdProcessor',
  'au.processors.type.odometerCleansingProcessor': 'OdometerCleansingProcessor',
  'au.processors.type.engineOilLifeCleansingProcessor': 'EngineOilLifeCleansingProcessor',
  'au.processors.type.telemetryReportCleansingProcessor': 'TelemetryReportCleansingProcessor',
  'au.processors.type.positionTimezoneOffsetProcessor': 'PositionTimezoneOffsetProcessor',
  'au.processors.type.vehicleDataUnpackProcessor': 'VehicleDataUnpackProcessor',
  'au.processors.type.fuelTypeCleansingProcessor': 'FuelTypeCleansingProcessor',
  'au.processors.type.VehicleDataDownsampleProcessor': 'VehicleDataDownsampleProcessor',
  'au.deployments.updatingState': 'The state of the deploy is updating... Please wait...',
  'au.deployments.deployStateFetchingError': 'An error was encountered while loading the state of the deploy.',
  'au.deployments.useConfidentialClient': 'Due to the permission issues, it is advised to use {loginLink}.',
  'au.deployments.confidentialClientLogin': 'confidential client login',
  'au.logs.bannerText': 'TMC retains event history for up to 6 months.',
  'au.rules.bannerText': '<b>Security Tip - </b>Simulate permits that are given by the rules or properties before generating it to ensure it behaves as expected.',
  'au.archivedRules.bannerText': 'These are rules that have been updated or deleted',
  'au.archivedRules.validUntil': 'Valid until {timestamp}',
  'au.commands.typesWait': 'Loading command types...',
  'au.commands.regions.NA': 'North America',
  'au.commands.regions.SA': 'South America',
  'au.commands.regions.EU': 'Europe',
  'au.commands.regions.CN': 'China',
  'au.commands.regions.RW': 'Rest of World',
  'au.commands.regions.NC': 'Not Configured',
  'au.commands.types.userAuthConfirmation': "User Auth Confirmation",
  'au.commands.types.authorizationStatusChange': "Authorization Status Change",
  'au.commands.types.retrieveDevicePairingCode': "Retrieve Device Pairing Code",
  'au.commands.types.validateVehicleClaim': "Validate Vehicle Claim",
  'au.commands.types.ccsUpdate': "CCS Update",
  'au.commands.types.ccsSettingsUpdate': "CCS Settings Update",
  'au.commands.types.ccsPoliciesAndSettingsUpdate': "CCS Policies and Settings Update",
  'au.commands.types.ccsUserPrompt': "CCS User Prompt",
  'au.commands.types.moduleDiscovery': "Module Discovery",
  'au.commands.types.deviceDiagnostic': "Device Diagnostic",
  'au.commands.types.fnvDeviceDiagnostic': "FNV Device Diagnostic",
  'au.commands.types.deliverVehicleInterrogationSchema': "Deliver Vehicle Interrogation Schema",
  'au.commands.types.remoteDiagnostic': "Remote Diagnostic",
  'au.commands.types.reportVilSnapshot': "Report VIL Snapshot",
  'au.commands.types.acknowledgeHmi': "Acknowledge HMI",
  'au.commands.types.hmiApplicationMessage': "HMI Application Message",
  'au.commands.types.beginIvsuCampaign': "Begin IVSU Campaign",
  'au.commands.types.cancelIvsuCampaign': "Cancel IVSU Campaign",
  'au.commands.types.deliverIvsuPolicyUpdate': "Deliver IVSU Policy Update",
  'au.commands.types.offPeakUpdate': "Off Peak Update",
  'au.commands.types.masterReset': "Master Reset",
  'au.commands.types.clearUserSettings': "Clear User Settings",
  'au.commands.types.userReset': "User Reset",
  'au.commands.types.softReset': "Soft Reset",
  'au.commands.types.featureReset': "Feature Reset",
  'au.commands.types.remoteMasterReset': "Remote Master Reset",
  'au.commands.types.remoteBrandReset': "Remote Brand Reset",
  'au.commands.types.remoteSyncReset': "Remote Sync Reset",
  'au.commands.types.statusRefresh': "Status Refresh",
  'au.commands.types.lock': "Lock",
  'au.commands.types.unlock': "Unlock",
  'au.commands.types.remoteStart': "Remote Start",
  'au.commands.types.cancelRemoteStart': "Cancel Remote Start",
  'au.commands.types.configureTelemetryEmission': "Configure Telemetry Emission",
  'au.commands.types.configurationUpdate': "Configuration Update",
  'au.commands.types.startVehicleCue': "Start Vehicle Cue",
  'au.commands.types.stopVehicleCue': "Stop Vehicle Cue",
  'au.commands.types.startPanicCue': "Start Panic Cue",
  'au.commands.types.stopPanicCue': "Stop Panic Cue",
  'au.commands.types.changeHomeUrl': "Change Home Url",
  'au.commands.types.ipPassthrough': "IP Passthrough",
  'au.commands.types.apn2Config': "Apn2 Config",
  'au.commands.types.activateRetailProfile': "Activate Retail Profile",
  'au.commands.types.remoteVehicleConfigurationManagement': "Remote Vehicle Configuration Management",
  'au.commands.types.setSignalListCollection': "Set Signal List Collection",
  'au.commands.types.startDataCollection': "Start Data Collection",
  'au.commands.types.stopDataCollection': "Stop Data Collection",
  'au.commands.types.wifiStatus': "Wi-Fi Status",
  'au.commands.types.wifiUsage': "Wi-Fi Usage",
  'au.commands.types.wifiConfiguration': "Wi-Fi Configuration",
  'au.commands.types.addOnboardGeofence': "Add Onboard Geofence",
  'au.commands.types.deleteOnboardGeofence': "Delete Onboard Geofence",
  'au.commands.types.updateOnboardGeofence': "Update Onboard Geofence",
  'au.commands.types.getOnboardGeofenceById': "Get Onboard Geofence By Id",
  'au.commands.types.getOnboardGeofenceIds': "Get Onboard Geofence Ids",
  'au.commands.types.telemetryCollectionThrottleConfiguration': "Telemetry Collection Throttle Configuration",
  'au.commands.types.telemetryCollectionSpeedRangeConfiguration': "Telemetry Collection Speed Range Configuration",
  'au.commands.types.telemetryCollectionRoadClassConfiguration': "Telemetry Collection Road Class Configuration",
  'au.commands.types.telemetryCollectionUploadIntentConfiguration': "Telemetry Collection Upload Intent Configuration",
  'au.commands.types.activateTheftMode': "Activate Theft Mode",
  'au.commands.types.deactivateTheftMode': "Deactivate Theft Mode",
  'au.commands.types.cancelEarlyTheftModeReporting': "Cancel Early Theft Mode Reporting",
  'au.commands.types.reportCurrentLocation': "Report Current Location",
  'au.commands.types.keyRotation': "Key Rotation",
  'au.commands.types.roadDataCollectionConfiguration': "Road Data Collection Configuration",
  'au.commands.types.opaqueCommand': "Opaque Command",
  'au.commands.types.heating': "Heating",
  'au.commands.types.cooling': "Cooling",
  'au.commands.types.opaque': "Opaque",
  'au.commands.types.dispatchRoute': "Dispatch Route",
  'au.commands.types.sendCameraStatus': "Send Camera Status",
  'au.commands.types.startTrailerLightCheck': "Start Trailer Light Check",
  'au.commands.types.stopTrailerLightCheck': "Stop Trailer Light Check",
  'au.commands.types.startVideoStream': "Start Video Stream",
  'au.commands.types.stopVideoStream': "Stop Video Stream",
  'au.commands.types.roadDataPrivacyConfiguration': "Road Data Privacy Configuration",
  'au.commands.types.setupVideoStreamSession': "Setup Video Stream Session",
  'au.commands.types.teardownVideoStreamSession': "Teardown Video Stream Session",
  'au.commands.types.unlockCargo': "Unlock Cargo",
  'au.commands.types.unlockCabin': "Unlock Cabin",
  'au.commands.types.unknown': "Unknown",
  'au.commands.types.open': "Open",
  'au.commands.types.applyVehicleInhibition': "Apply Vehicle Inhibition",
  'au.commands.types.applyTheftInhibition': "Apply Theft Inhibition",
  'au.commands.types.removeTheftInhibition': "Remove Theft Inhibition",
  'au.commands.types.onlineTrafficDataMessage': "Online Traffic Data Message",
  'au.commands.types.onlineTrafficSessionAcknowledgement': "Online Traffic Session Acknowledgement",
  'au.commands.types.remoteAdasReset': "Remote Adas Reset",
  'au.commands.types.remoteRecoveryReset': "Remote Recovery Reset",
  'au.commands.types.removeVehicleInhibition': "Remove Vehicle Inhibition",
  'au.commands.types.scheduleVehicleInhibition': "Schedule Vehicle Inhibition",
  'au.commands.types.fetchFnvDeviceDiagnosticStatus': "Fetch FNV Device Diagnostic Status",
  'au.commands.types.onlineTrafficDataAcknowledgement': "Online Traffic Data Acknowledgement",
  'au.failureMode.allFailureModes': 'Apply All Failure Modes',
  'au.failureMode.beforeDeliveryFailure': 'Failure Before Delivery',
  'au.failureMode.afterDeliveryFailure': 'Failure After Delivery',
  'au.failureMode.beforeDeliveryExpiration': 'Expiration Before Delivery',
  'au.failureMode.afterDeliveryExpiration': 'Expiration After Delivery',
  'au.failureMode.beforeDeliveryFailureType': 'Type: Failure Before Delivery',
  'au.failureMode.afterDeliveryFailureType': 'Type: Failure After Delivery',
  'au.failureMode.beforeDeliveryExpirationType': 'Type: Expiration Before Delivery',
  'au.failureMode.afterDeliveryExpirationType': 'Type: Expiration After Delivery',
  'au.failureMode.allPreclusions': 'Apply All Preclusions',
  'au.failureMode.preclusionType': 'Type: Expiration or Failure due to preclusion',
  'au.failureMode.preclusion': 'preclusion',
  'au.failureMode.types.UNKNOWN_FAILURE_TYPE': 'Unknown Failure Type',
  'au.failureMode.types.DEVICE_VALIDATION_FAILURE': 'Device Validation Failure',
  'au.failureMode.types.DEVICE_EXECUTION_FAILURE': 'Device Execution Failure',
  'au.failureMode.types.INVALID_DEVICE_RESPONSE_FAILURE': 'Invalid Device Response Failure',
  'au.failureMode.types.INVALID_COMMAND_PAYLOAD_FAILURE': 'Invalid Command Payload Failure',
  'au.failureMode.types.INTERNAL_TMC_ERROR': 'Internal TMC Error',
  'au.failureMode.types.PRECONDITION_FAILURE': 'Precondition Failure',
  'au.failureMode.types.OEM_CLOUD_TRANSLATION_FAILURE': 'OEM Cloud Translation Failure',
  'au.failureMode.types.OEM_CLOUD_DELIVERY_FAILURE': 'OEM Cloud Delivery Failure',
  'au.failureMode.types.DEVICE_DELIVERY_FAILURE': 'Device Delivery Failure',
  'au.failureMode.types.UNKNOWN_EXPIRATION_TYPE': 'Unknown Expiration Type',
  'au.failureMode.types.DEVICE_NEVER_CONNECTED': 'Device Never Connected',
  'au.failureMode.types.PRIOR_COMMANDS_IN_PROGRESS': 'Prior Commands in Progress',
  'au.failureMode.types.OEM_CLOUD_DELIVERY_TIMEOUT': 'OEM Cloud Delivery Timeout',
  'au.failureMode.types.EXPIRED_DURING_DELIVERY': 'Expired During Delivery',
  'au.failureMode.types.DEVICE_NEVER_RESPONDED': 'Device Never Responded',
  'au.failureMode.types.COMMANDS_PRECLUDED_BY_FIRMWARE_UPDATE': 'Firmware Update',
  'au.failureMode.types.COMMANDS_PRECLUDED_BY_DEEP_SLEEP': 'Deep Sleep',
  'au.failureMode.types.COMMANDS_PRECLUDED_BY_CARRIER_REGISTRATION': 'Carrier Registration',
  'au.failureMode.types.COMMANDS_PRECLUDED_BY_TEMPERATURE_THRESHOLD': 'Temperature Threshold',
  'au.quickActions.taps': 'Taps',
  'au.quickActions.taps.text': 'Taps filter streaming data.',
  'au.quickActions.flows': 'Flows',
  'au.quickActions.flows.text': 'Flows allow the consumption of streaming data.',
  'au.quickActions.clients': 'Clients',
  'au.quickActions.clients.text': 'Clients control access to the TMC.',
  'au.quickActions.groups': 'Groups',
  'au.quickActions.groups.text': 'Groups allow bundling of Vehicles and Devices.',
  'au.quickActions.title': 'Quick Actions',
  'au.topologyLegend.title': 'TMC Topology Legend',
  'au.topologyLegend.text': 'View Topology',
  'au.multiSaveDialog.close': 'Close',
  'au.multiSaveDialog.copyToClipboard': 'Copy to Clipboard',
  'au.multiSaveDialog.message.create.success': 'You have successfully created {count, plural, one {a} other {#}} new {entityName}.',
  'au.multiSaveDialog.message.create.failure': 'Error occurred while creating a {entityName}.',
  'au.multiSaveDialog.message.create.processing': 'Creating {count, plural, one {a} other {#}} new {entityName}...{more, plural, =0 {} other { <hint># more left</hint>}}',
  'au.multiSaveDialog.message.create.aborted': "{entityName} won't be created.",
  'au.multiSaveDialog.message.edit.success': 'You have successfully updated {count, plural, one {an} other {#}} existing {entityName}.',
  'au.multiSaveDialog.message.edit.failure': 'Error occurred while updating a {entityName}.',
  'au.multiSaveDialog.message.edit.processing': 'Updating {count, plural, one {an} other {#}} existing {entityName}...{more, plural, =0 {} other { <hint># more left</hint>}}',
  'au.multiSaveDialog.message.edit.aborted': "{entityName} won't be updated.",
  'au.dayInput.placeholder': 'MM/DD/YYYY',
  'au.dayInput.placeholderMMDD': 'MM/DD',
  'au.timeInput.placeholder': 'HH:MM:SS',
  'au.filterDetails.deviceType': 'Device Type',
  'au.filterDetails.groupId': 'Id',
  'au.filterDetails.tagKey': 'Key',
  'au.filterDetails.tagValue': 'Value',
  'au.fork.permitError': 'Every output is required to have a permit filter associated with it',
  'au.userGroup.name': 'Name',
  'au.userGroup.displayName': 'Display Name',
  'au.userGroup.dialogs.displayName': 'Display Name: <b>{displayName}</b>',
  'au.userGroup.dialogs.username': 'Username: <b>{username}</b>',
  'au.userGroup.username': 'Username',
  'au.userGroup.userGroupId': 'Id',
  'au.userGroup.creatorAui': 'Creator',
  'au.userGroup.tags': "Tags",
  'au.userGroup.confirmDeleteTitle': 'Delete User Group',
  'au.userGroup.confirmDelete': `You are about to delete this user group. {br}{br}Policy statements for the user group will still remain, but will be ineffective in determining access. The deleted user group can be recovered upon request within 30 days after deletion.{br}{br}Would you still like to continue?`,
  'au.userGroup.deleteSuccess': 'The User Group was successfully deleted.',
  'au.userGroup.idOrName': 'Id or Name',
  'au.userGroup.idOrUsername': 'Id or Username',
  'au.userGroup.limitExceed': 'Account reached the maximum number of user groups.',
  'au.userGroup.members.clients': 'Clients',
  'au.userGroup.members.users': 'Users',
  'au.userGroup.members.delete': 'Remove',
  'au.userGroup.members.deleteMember': 'Delete Member',
  'au.userGroup.members.undo': 'Undo',
  'au.userGroup.members.updateTime': 'Update Time',
  'au.userGroup.members.createTime': 'Create Time',
  'au.userGroup.members.delete.title': 'Remove {entityName}',
  'au.userGroup.members.delete.description': 'This action cannot be undone. Are you sure you want to remove the {entityName} from the user group {displayName}?',
  'au.userGroup.members.deletedEntity': 'Removed {entityName}: ',
  'au.userGroup.members.deleteSuccess': 'The {entityName} was successfully removed from the user group {displayName}.',
  'au.membership.userGroup.delete.title': 'Remove {entityName} from User Group',
  'au.membership.userGroup.delete.description': 'This action cannot be undone. Are you sure you want to remove the {entityName} from the user group?',
  'au.membership.userGroup.deletedEntity': 'Removed User Group name: ',
  'au.membership.userGroup.deleteSuccess': 'The {entityName} was successfully removed from the user group.',
  'au.membership.userGroup.failureToCreate': 'Failure to add one or more members to the new user group. Please retry.',
  'au.userGroup.members.user': 'User',
  'au.userGroup.members.client': 'Client',
  'au.userGroup.members.notFound': '{value} not found.',
  'au.userGroup.members.notValid': 'Client not valid. Should be type CONFIDENTIAL_WITH_SERVICE_ACCOUNT',
  'au.userGroup.members.exists': '{value} is already a member of the user group.',
  'au.userGroup.members.add': 'Add',
  'au.userGroup.members.markedForRemoval': 'Marked for removal',
  'au.fork.pause': 'Pause Fork',
  'au.fork.start': 'Start Fork',
  'au.fork.message': 'Please choose the data resume point. When the fork is started, its data stream will pick up at the timestamp you specified.',
  'au.latest': 'Latest',
  'au.pointOfPause': 'Point of pause',
  'au.earliest': 'Earliest',
  'au.custom': 'Custom',
  'au.hr': '{hours, plural, one {1 hr} other {# hrs}}',
  'au.dataResumeLimit': 'The data resume is limited to last 12 hours.',
  'au.attributeTagFilterValue.hint': "When this filter is permitted, the tap will block telemetry data that doesn't match the specified attribute tag filters.",
  'au.tap.start': 'Start Tap',
  'au.tap.message': 'Please choose the data resume point. When the tap is started, its data stream will pick up at the timestamp you specified.',
  'au.fork.waitForReplay': '<b>Replaying -</b> Please wait while replaying old data. There are some update restrictions during this time.',
  'au.topology.validationStatuses.PERMIT_PERMITTED.state': 'VALID',
  'au.topology.validationStatuses.PERMIT_PERMITTED.description': "The test filter is a PERMIT filter and there was at least one permitted match in the target filter set an no explicit denies. So, signals matching the test filter would be allowed by the target filter set.",
  'au.topology.validationStatuses.DENY_PERMITTED.state': 'VALID',
  'au.topology.validationStatuses.DENY_PERMITTED.description': "The test filter is a DENY filter and there is at least one permitted match of the target filter set and no explicit denies. So, signals allowed to pass by the target filter set would be denied by the test filter.",
  'au.topology.validationStatuses.PERMIT_DEFAULT_DENY.state': 'INVALID',
  'au.topology.validationStatuses.PERMIT_DEFAULT_DENY.description': "The test filter is a PERMIT filter, but there were no matches in the target filter set, so signals matching the test filter would be default denied by the target filter set.",
  'au.topology.validationStatuses.PERMIT_EXPLICIT_DENY.state': 'INVALID',
  'au.topology.validationStatuses.PERMIT_EXPLICIT_DENY.description': "The test filter is a PERMIT filter, but there were explicit deny filters found in the target filter set that matched the test filter. So, signals that were denied by the target filter set would never reach the test filter.",
  'au.topology.validationStatuses.DENY_DEFAULT_DENY.state': 'WARNING',
  'au.topology.validationStatuses.DENY_DEFAULT_DENY.description': "The test filter is a DENY filter, and no matches were found in the target filter set. So, any signals that would be denied by the test filter are not being passed by the target filter set.",
  'au.topology.validationStatuses.DENY_EXPLICIT_DENY.state': 'WARNING',
  'au.topology.validationStatuses.DENY_EXPLICIT_DENY.description': "The test filter is a DENY filter and there was an explicit match to at least one of the filters in the target set. So any signals that would be denied by the test filter are already blocked by the target filter set.",
  'au.topology.validationStatuses.PERMIT_REGEX_PARTIAL_PERMIT.state': 'VALID',
  'au.topology.validationStatuses.PERMIT_REGEX_PARTIAL_PERMIT.description': "The test filter is a PERMIT filter that is a regular expression. At least one of the target filter set is permitted. This handles the case where a test string might be a wild card (.*) and the targets are a set of strings, some of which are permitted and some of which are denied.",
  'au.topology.validationStatuses.PERMIT_REGEX_DEFAULT_DENY.state': 'WARNING',
  'au.topology.validationStatuses.PERMIT_REGEX_DEFAULT_DENY.description': "Both test and target strings are regexes and the test filter is permitted, but no overlap could be discovered between the two regex sets.",
  'au.topology.validationStatuses.DENY_REGEX_DEFAULT_DENY.state': 'WARNING',
  'au.topology.validationStatuses.DENY_REGEX_DEFAULT_DENY.description': "Both test and target strings are regexes and the test filter is denied, but no overlap could be discovered between the two regex sets.",
  'au.topology.validationStatuses.PERMIT_PARTIAL_DENY.state': 'INVALID',
  'au.topology.validationStatuses.PERMIT_PARTIAL_DENY.description': "The test filter is either a GROUP or VIN filter and is configured to PERMIT the VINs in the set. However, a subset of the VINs in the test filter are explicitly denied by the target filter set.",
  'au.topology.validationStatuses.DENY_PARTIAL_DENY.state': 'WARNING',
  'au.topology.validationStatuses.DENY_PARTIAL_DENY.description': "The test filter is either a GROUP or VIN filter and is configured to DENY the VINs in the set. A subset of the VINs in the test set are already explicitly denied by the target filter set.",
  'au.topology.validationStatuses.PERMIT_PARTIAL_PERMIT.state': 'INVALID',
  'au.topology.validationStatuses.PERMIT_PARTIAL_PERMIT.description': "The test filter is either a GROUP or VIN filter and is configured to PERMIT the VINs in the set. However, a subset of the VINs in the test set are not matched by any permitted or explicitly denied VINs in the target set. So this subset of VINs are default denied.",
  'au.topology.validationStatuses.DENY_PARTIAL_PERMIT.state': 'WARNING',
  'au.topology.validationStatuses.DENY_PARTIAL_PERMIT.description': "The test filter is either a GROUP or VIN filter and is configured to DENY the VINs in the set. However, a subset of the VINs in the test set are not matched by any permitted or explicitly denied VINs in the target set. So this subset of VINs is already default denied.",
  'au.topology.validationStatuses.PERMIT_PARTIAL_PERMIT_DENY.state': 'INVALID',
  'au.topology.validationStatuses.PERMIT_PARTIAL_PERMIT_DENY.description': "The test filter is either a GROUP or VIN filter and is configured to PERMIT the VINs in the set. However, there is a subset of VINs in the target set that are explicitly blocked and another set that are permitted.",
  'au.topology.validationStatuses.DENY_PARTIAL_PERMIT_DENY.state': 'VALID',
  'au.topology.validationStatuses.DENY_PARTIAL_PERMIT_DENY.description': "The test filter is either a GROUP or VIN filter and is configured to DENY the VINs in the set. There is a subset of VINs in the target set that are explicitly blocked and another set that are permitted. This validation level is set to VALID as in either case, the test filter is blocking at least a subset of permitted VINs.",
  'au.topology.validationStatuses.IAM_MISMATCH_WARNING.state': 'WARNING',
  'au.topology.validationStatuses.IAM_MISMATCH_WARNING.description': "The test filter is an IAM filter and does not match the target IAM filter.",
  'au.topology.validationStatuses.PERMIT_EMPTY_GROUP.state': 'VALID',
  'au.topology.validationStatuses.PERMIT_EMPTY_GROUP.description': "The test or target group contains no entries.",
  'au.topology.validationStatuses.DENY_EMPTY_GROUP.state': 'VALID',
  'au.topology.validationStatuses.DENY_EMPTY_GROUP.description': "The test or target group contains no entries.",
  'au.topology.validationStatuses.VALIDATION_TIMEOUT.state': 'VALIDATION_TIMEOUT',
  'au.topology.validationStatuses.VALIDATION_TIMEOUT.description': 'Validation has timed out while attempting to validate a GROUP or VIN filter. This can happen when the service is required to perform a large number of assets and/or group service gRPC calls. Either disabling or limiting the number of vins to check will reduce the likelihood of this timeout occurring.',
  'au.topology.validationStatuses.VIN_MATCH_DISABLED.state': 'VIN_MATCH_DISABLED',
  'au.topology.validationStatuses.VIN_MATCH_DISABLED.description': 'VIN matching is disabled and a VIN filter validation was performed or a GROUP filter validation was performed that required looking up the VIN for an asset.',
  'au.topology.validationStatuses.MAX_VINS_EXCEEDED.state': 'MAX_VINS_EXCEEDED',
  'au.topology.validationStatuses.MAX_VINS_EXCEEDED.description': 'The number of VINs in a VIN filter or assets in a GROUP filter is greater than the maximum number of VINs supported (10K currently)',
};

const locales = {
  en: defaultEnglish,
  'en-GB': Object.assign({}, defaultEnglish, {
    'au.analytics.distChart.title': "Fleet Distance Travelled",
    'au.asset.analytics.distChart.title': "Distance Travelled",
  })
};

for (let locale of Object.keys(coreLocales)) {
  if (!locales[locale]) {
    delete coreLocales[locale];
  }
}

export default merge(coreLocales, locales);
